import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// TODO: implement this page so it's different from the products page
const MainPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/apps');
    }, [navigate]);

    return <></>
}

export default MainPage;