import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: { main: '#3b4658' },
        secondary: { main: '#f3f3f3' },
        error: { main: '#f44336' },
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                standardSuccess: {
                    backgroundColor: '#EDF7ED',
                    color: '#1E4620',
                    fontFamily: 'Roboto,Raleway,sans-serif',
                    fontWeight: '500',
                },
                standardError: {
                    backgroundColor: '#FDEDED',
                    color: '#5F2120',
                    fontFamily: 'Roboto,Raleway,sans-serif',
                    fontWeight: '500',
                },
                standardWarning: {
                    backgroundColor: '#FFF4E5',
                    color: '#663C00',
                    fontFamily: 'Roboto,Raleway,sans-serif',
                    fontWeight: '500',
                },
                standardInfo: {
                    backgroundColor: '#E5F6FD',
                    color: '#014361',
                    fontFamily: 'Roboto,Raleway,sans-serif',
                    fontWeight: '500',
                }
            }
        }
    }
});

export { theme };