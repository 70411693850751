import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { theme } from '../../../../styles/theme';
import { ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import HelpIcon from '@mui/icons-material/Help';
import TipCard from '../tip-card/TipCard';
import tutorialStep1 from '../../../../assets/cartx-sualoja2.png';
import tutorialStep2 from '../../../../assets/cartx-sualoja.png';
import tutorialStep3 from '../../../../assets/cartx-sualoja3.png';

export default function Tutorial() {
    const items = [
        {
            link: {
                href: 'https://accounts.cartpanda.com/login',
                description: 'Faça o login na Cartpanda',
            },
        },
        {
            text: 'Após ter se logado, clique sobre o nome da sua loja na parte superior direita da página. Uma pequena janela se abrirá.',
            image: {
                src: tutorialStep1,
                alt: 'Clique sobre o nome da sua loja',
                height: '3.75rem',
            }
        },
        {
            text: 'Clique no ícone de copiar/colar que fica debaixo do nome da sua loja para copiar seu token. Não se preocupe, não guardaremos ele, então só você terá acesso.',
            image: {
                src: tutorialStep2,
                alt: 'Copie o seu token',
                height: '12.5rem',
            },
        },
        {
            text: `Copie o nome da sua loja. Por exemplo, se a URL da sua loja é minhaloja.mycartpanda.com, então copie apenas o nome 'minhaloja' (sem a parte '.mycartpanda.com' ou '.oncartx.io').`,
            image: {
                src: tutorialStep3,
                alt: 'Copie o nome da sua loja',
                height: '12.5rem',
            },
        }
    ];
    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid>
                    <Grid xs={1} md={3}>
                        <Box/>
                    </Grid>
                    <Grid xs={10} md={6}>
                        <Box>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#373b3c' }}/>}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'
                                    sx={{
                                        backgroundColor: '#f9fafb',
                                        borderRadius: '0.625rem 0.625rem 0.625rem 0.625rem',
                                    }}
                                >
                                    <HelpIcon sx={{ color: '#373b3c' }}/>
                                    <Typography
                                        padding='0px 0px 0px 10px'
                                        sx={{
                                            color: '#373b3c',
                                            fontWeight: '600'
                                        }}
                                        >Tutorial</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{
                                        backgroundColor: '#f9fafb',
                                        borderRadius: '0 0 0.625rem 0.625rem'
                                    }}
                                >
                                    <TipCard
                                        title='Como obter as credenciais'
                                        items={items}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid xs={1} md={3}>
                        <Box />
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    );
}