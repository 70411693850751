import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const CardStyled = styled(Card)`
  min-width: 20rem;
  width: 25rem;
  max-width: 100%;
  align-items: center;
  text-align: center;
`;

export const CardTextStyled = styled(Card.Text)`
  padding: 1%;
  min-height: 3rem;
  align-items: center;
  text-align: center;
`;

export const LinkBtnStyled = styled(Card.Link)`
    text-decoration: none;
    color: #f3f3f3;
    background-color: #3b4658;
    font-family: "Roboto", "Raleway", sans-serif;
    font-weight: 400;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    cursor: ${(props) => props?.disabled ? 'not-allowed' : 'pointer'};
  	opacity: ${(props) => props?.disabled ? '0.5' : '1'};
  
    &:hover {
        text-decoration: none;
        color: #f3f3f3;
    }
  
    &:focus {
        text-decoration: none;
        color: #f3f3f3;
    }
`;

export const BtnStyled = styled.div`
    margin: 0 0.2rem 0 0.2rem;
`;

export const CTAContainerStyled = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ImgStyled = styled(Card.Img)`
  width: auto;
  height: auto;
  width: 140px;
  height: 140px;
  max-width: 100%;
  max-height: 100%;
  padding: 0.5rem 1rem;
`;

export const CardDemoVideo = styled.iframe`
  margin-bottom: 10%;
  border-radius: 20px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;