import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
    LoaderStyled,
    SpinnerStyled,
} from './LoaderStyled';

export const Loader = () => (
    <Row style={{ marginTop: '1%' }}>
        <Col xs={12}>
            <LoaderStyled>
                <SpinnerStyled/>
            </LoaderStyled>
        </Col>
    </Row>
);