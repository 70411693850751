import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';
import ImporterLandingPage from '../pages/importer/landing-page/LandingPage';
import SignIn from '../pages/users/sign-in/SignIn';
import SignUp from '../pages/users/sign-up/SignUp';
import EmailValidationPage from '../pages/users/email-validation/EmailValidationPage';
import ForgotPassword from '../pages/users/forgot-password/ForgotPassword';
import ResetPassword from '../pages/users/reset-password/ResetPassword';
import Profile from '../pages/users/profile/Profile';
import MainPage from '../pages/main-page/MainPage';
import AppsShowcasePage from '../pages/apps-showcase-page/AppsShowcasePage';
import AppsSettingsPage from '../pages/users/settings/Settings';
import Checkout from '../pages/payments/checkout/Checkout';
// import Cart from '../pages/payments/cart/Cart';
import AuthorizedPage from '../components/middlewares/authorization/AuthorizedPage';
import Doc from '../components/document/Doc';
import termsDoc from '../docs/termos_copycat.pdf';
import privacyDoc from '../docs/privacidade_copycat.pdf';
import { CustomMessage } from '../components/custom-message/CustomMessage';

const Routes = () => (
    <Switch>
        <Route exact path='/' element={<MainPage/>} />
        <Route exact path='/importer' element={<ImporterLandingPage/>} />
        <Route exact path='/sign-up' element={<SignUp/>} />
        <Route exact path='/validate-email' element={<EmailValidationPage/>} />
        <Route exact path='/sign-in' element={<SignIn/>} />
        <Route exact path='/forgot-password' element={<ForgotPassword/>} />
        <Route exact path='/reset-password' element={<ResetPassword/>} />
        <Route exact path='/profile' element={<AuthorizedPage><Profile/></AuthorizedPage>} />
        <Route exact path='/settings' element={<AuthorizedPage><AppsSettingsPage/></AuthorizedPage>} />
        <Route exact path='/apps' element={<AppsShowcasePage/>} />
        {/*<Route exact path='/my-cart' element={<Cart/>} />*/}
        <Route exact path='/plans' element={<Checkout/>} />
        <Route exact path='/checkout/success' element={<CustomMessage notFound={false} message='Pagamento efetuado com sucesso!' />} />
        <Route exact path='/checkout/cancel' element={<CustomMessage notFound={false} message='Falha no pagamento, tente com outro método!'/>} />
        <Route exact path='/terms' element={<Doc file={termsDoc}/>} />
        <Route exact path='/privacy' element={<Doc file={privacyDoc}/>} />
    </Switch>
);

export default Routes;