import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const SettingsContainer = styled(Container)`
    background-color: #f9fafb;
    border: 1px solid #f3f4f6;
    padding: 2% 0 2% 0;
    border-radius: 28px;

    @media (min-width: 0px) {
      max-width: 90%; /* For xs devices */
    }

    @media (min-width: 576px) {
        max-width: 75%; /* For sm devices */
    }

    @media (min-width: 768px) {
        max-width: 65%; /* For md devices */
    }

    @media (min-width: 992px) {
        max-width: 55%; /* For lg devices */
    }

    @media (min-width: 1200px) {
        max-width: 50%; /* For xl devices */
    }

    @media (min-width: 1400px) {
        max-width: 45%; /* For xxl devices */
    }

`;

export const Title = styled.h2`
  font-family: DM Sans, Inter, Arial, sans-serif;
  font-weight: 600;
  line-height: 128%;
  color: #374151;
  margin-bottom: 2%;
`;