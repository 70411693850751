import axios from 'axios';

const paymentsClient = axios.create({
    baseURL: process.env.REACT_APP_PAYMENTS_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default paymentsClient;
