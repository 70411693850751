export const firstNameFormatter = (name) => {
    let firstName = ""
    if (name) {
        firstName = name.trim().split(" ")[0].toLowerCase();
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    }
    return firstName;
}

export const truncateString = (str = '', limit = 0, strAfterTruncate = '...') => {
    const newStr = str?.substring(0, limit)
    
    if (newStr?.length === str?.length) {
        return newStr
    }
    
    return `${newStr}${strAfterTruncate}`;
}