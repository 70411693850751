import React, { useContext, useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    Alert,
    Zoom,
    Tooltip,
    Stack,
} from '@mui/material';
import {
    HeaderStyled,
    TitleStyled,
    ButtonStyled,
    GridItemStyled,
    GridContainerStyled,
    ButtonLabelStyled,
    EditLinkStyled,
} from './FormStyled';
import UsageWarning from '../../../../components/warning/UsageWarning';
import { theme } from '../../../../styles/theme';
import { ThemeProvider } from '@mui/material';
import { useImporterUsage } from '../../../../hooks/useImporterUsage';
import { useImporterCopy } from '../../../../hooks/useImporterCopy';
import { UserContext } from '../../../../contexts/UserContext';
import { Form as BootstrapForm } from 'react-bootstrap';
import importerService from "../../../../services/importerService";

const Form = ({ setPlgError }) => {
    const [formValues, setFormValues] = useState({
        url: '',
        storeSlug: '',
        token: '',
    });

    const [usage, setUsage] = useState({
        usage: '',
        tier: '',
        tierLimitReached: false,
    });
    const [publishAfterImport, setPublishAfterImport] = useState(false);
    const [importVariants, setImportVariants] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [linkToEditProduct, setLinkToEditProduct] = useState('');

    const getImporterUsage = useImporterUsage();
    const postImporterCopy = useImporterCopy();

    // Add UserContext as dependency to trigger the useEffect that updates the app usage
    const { user } = useContext(UserContext);

    const defaultFieldsLabels = {
        url: 'Link do produto',
        storeSlug: 'Seu domínio Cartpanda',
        token: 'Seu token Cartpanda',
    };

    const defaultToolTips = {
        url: 'Insira aqui o link completo da página de produto que você deseja importar. No momento, suportamos a importação de produtos de lojas Shopify e Cartpanda apenas. Ex: https://nomedalojashopify.com/products/nome-do-produto',
        storeSlug: `Insira aqui o nome da sua loja no domínio Cartpanda. Você deve colocar apenas o nome da loja, sem 'https://' e sem '.mycartpanda.com' ou '.oncartx.io'. Ex: No caso do domínio 'minha-loja.mycartpanda.com', bastaria preencher o campo apenas com 'minha-loja', sem as aspas`,
        token: 'Insira aqui o seu token Cartpanda. Tanto o vídeo anterior quanto o tutorial abaixo mostram o passo-a-passo para você acessar e copiar o seu token Cartpanda',
    };

    useEffect(() => {
        const importerSettings = importerService.getImporterSettings();

        if (importerSettings?.publishAfterImport !== undefined) {
            setPublishAfterImport(importerSettings.publishAfterImport);
        }

        if (importerSettings?.importVariants !== undefined) {
            setImportVariants(importerSettings.importVariants);
        }

    }, []);

    useEffect(() => {
        const setImporterUsage = async () => {
            const { status, data } = await getImporterUsage();
            if (status === 200 && data?.usage) {
                setUsage(data.usage);
            }
        }

        setImporterUsage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // UseEffect that updates the style of PlgAlert if the user reaches its tier limit
    useEffect(() => {
        setPlgError(usage.tierLimitReached);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usage]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let message = 'Ops... alguma coisa deu errado, tente novamente mais tarde!';
        const requiredFieldsEmpty = Object.keys(formValues)
            .filter((formField) => formValues[formField] === '')
            .map((formField) => defaultFieldsLabels[formField]?.toLowerCase())

        if (requiredFieldsEmpty?.length) {
            return setErrorMessage(`Falta preencher os seguintes campos: ${requiredFieldsEmpty.join(', ')}.`);
        }

        try {
            const { status, data } = await postImporterCopy({
                ...formValues,
                publishAfterImport,
                importVariants,
            });
            // console.log('status...', status);
            // console.log('data...', data);
            if (status !== 200) {
                setErrorMessage(data?.message || message);
            } else {
                if (data?.product?.id) {
                    setLinkToEditProduct(`https://accounts.cartpanda.com/products/edit/${data.product.id}`)
                }

                setSuccessMessage('Produto importado com sucesso para a sua loja. As imagens e variantes podem demorar mais.');
            }
            if (data?.usage) {
                setUsage(data.usage);
            }
        } catch (error) {
            setErrorMessage(message);
        }
    };

    const handleCloseAlertError = () => {
        setErrorMessage('');
    };

    const handleCloseAlertSuccess = () => {
        setSuccessMessage('');
    };

    const handleCloseAlertEdit = () => {
        setLinkToEditProduct('');
    };

    useEffect(() => {
        setTimeout(() => {
            if (errorMessage) { setErrorMessage(''); }
            if (successMessage) { setSuccessMessage(''); }
            if (linkToEditProduct) { setLinkToEditProduct(''); }
        }, 15000);
    }, [errorMessage, successMessage, linkToEditProduct]);

    return (
        <ThemeProvider theme={theme}>
            <HeaderStyled>
                <TitleStyled>
                    Preencha os campos abaixo para importar o produto
                </TitleStyled>
            </HeaderStyled>
            <GridContainerStyled>
                <form onSubmit={handleSubmit} autoComplete='on'>
                    <Grid container alignItems='center' justifyContent='center' direction='column'>
                        {Object.keys(defaultFieldsLabels).map((field) => (
                            <GridItemStyled key={field}>
                                <Grid item>
                                    <Tooltip title={defaultToolTips[field]} placement='right'>
                                        <TextField
                                            id={`${field}-input`}
                                            name={field}
                                            label={defaultFieldsLabels[field]}
                                            type='text'
                                            onChange={handleInputChange}
                                            disabled={!!usage.tierLimitReached}
                                        />
                                    </Tooltip>
                                </Grid>
                            </GridItemStyled>
                        ))}
                        <GridItemStyled>
                            <BootstrapForm.Check
                                style={{ marginTop: '10px' }}
                                type='checkbox'
                                id='importVariants'
                                label='Importar variantes automaticamente'
                                checked={importVariants}
                                onChange={() => setImportVariants(!importVariants)}
                                disabled={!!usage.tierLimitReached}
                            />
                            <BootstrapForm.Check
                                style={{ marginTop: '10px' }}
                                type='checkbox'
                                id='publishAfterImport'
                                label='Publicar produto automaticamente'
                                checked={publishAfterImport}
                                onChange={() => setPublishAfterImport(!publishAfterImport)}
                                disabled={!!usage.tierLimitReached}
                            />
                        </GridItemStyled>
                        {/*<GridItemStyled>*/}
                        {/*    <BootstrapForm.Check*/}
                        {/*        type='checkbox'*/}
                        {/*        id='importVariants'*/}
                        {/*        label='Importar variantes automaticamente'*/}
                        {/*        checked={importVariants}*/}
                        {/*        onChange={() => setImportVariants(!importVariants)}*/}
                        {/*        disabled={!!usage.tierLimitReached}*/}
                        {/*    />*/}
                        {/*</GridItemStyled>*/}
                        <ButtonStyled>
                            <Button variant='contained' color='primary' type='submit' disabled={ !!usage.tierLimitReached }>
                                <ButtonLabelStyled>Copycat</ButtonLabelStyled>
                            </Button>
                        </ButtonStyled>
                        <UsageWarning {...usage} />
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            {!!errorMessage && <Zoom in={!!errorMessage}>
                                <Alert severity='error' onClose={handleCloseAlertError}>{errorMessage}</Alert>
                            </Zoom>}
                            {!!successMessage && <Zoom in={!!successMessage}>
                                <Alert severity='success' onClose={handleCloseAlertSuccess}>{successMessage}</Alert>
                            </Zoom>}
                            {!!linkToEditProduct && <Zoom in={!!linkToEditProduct}>
                                <Alert severity='info' onClose={handleCloseAlertEdit}>
                                    <EditLinkStyled to={linkToEditProduct} target='_blank'>Clique aqui</EditLinkStyled> para editar o produto na sua loja. As imagens e variantes ainda podem estar sendo importadas.
                                </Alert>
                            </Zoom>}
                        </Stack>
                    </Grid>
                </form>
            </GridContainerStyled>
        </ThemeProvider>
    );
};

export default Form;