import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

export const TipRow = styled(Row)`
  justify-content: center;
`;

export const TipCol = styled(Col)`
  justify-content: center;
`;

export const TipContainer = styled.div`
  width: 80%;
  height: 100%;
  max-width: 100%;
  margin-top: 1%;
  padding: 3% 5% 0 5%;
  background-color: #f9fafb;
  border-radius: 1rem;
`;

export const TipList = styled.ol`
  list-style-type: decimal;
  color: #1f2a37;
  font-weight: 400;
  margin: 1% 0 0 0;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
  list-style-position: inside;
`;

export const TipItem = styled.li`
  justify-self: flex-start;
  text-align: justify;
  justify-content: center;
  align-items: center;
`;

export const TipText = styled.span`
  font-family: Inter, DM Sans, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 144%;
  color: #373b3c;

  @media (max-width: 991px) {
    font-size: 2vw;
  }
`;

export const TipImg = styled.img`
  padding: 1%;
  text-align: center;
  align-self: center;
  justify-self: center;
  display: block;
  justify-content: center;
  vertical-align: middle;
  margin: 1% auto;
  border-radius: 1.25rem;
  min-height: 2rem;
  min-width: 6rem;
  height: ${props => props?.height || 'auto'};
  width: auto;
  max-height: 100%;
  max-width: 100%;
`;

export const TipTitle = styled.span`
  font-family: "Roboto", "Raleway", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 128%;
  color: #374151;

  @media (max-width: 991px) {
    font-size: 2.5vw;
  }
`;

export const ContainerStyled = styled.a`
  text-decoration: none;
  cursor: pointer;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    color: #4b5563;
  }

  &:-webkit-any-link {
    text-decoration: none;
    color: #4b5563;
    cursor: pointer;
  }

  @media screen and (max-width: 991px) {
    font-size: 2vw;
  }
`;