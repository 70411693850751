import styled from 'styled-components';

export const ButtonStyled = styled.div`
  margin: ${(props) => props?.margin ? props?.margin : ''};
  cursor: ${(props) => props?.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${(props) => props?.disabled ? '0.5' : '1'};
`;

export const ButtonLabelStyled = styled.span`
  font-family: "Roboto", "Raleway", sans-serif;
  font-size: ${(props) => props?.fontSize ? props?.fontSize : '1rem'};
  font-weight: ${(props) => props?.fontWeight ? props?.fontWeight : '400'};
`;