import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { Link as RouterLink } from 'react-router-dom';

export const SignInContainer = styled(Container)`
    background-color: #f9fafb;
    border: 1px solid #f3f4f6;
    padding: 2% 0 2% 0;
    border-radius: 28px;

    @media (min-width: 0px) {
      max-width: 90%; /* For xs devices */
    }

    @media (min-width: 576px) {
        max-width: 75%; /* For sm devices */
    }

    @media (min-width: 768px) {
        max-width: 65%; /* For md devices */
    }

    @media (min-width: 992px) {
        max-width: 55%; /* For lg devices */
    }

    @media (min-width: 1200px) {
        max-width: 50%; /* For xl devices */
    }

    @media (min-width: 1400px) {
        max-width: 45%; /* For xxl devices */
    }

`;

export const ErrorMsg = styled.span`
  font-family: Inter, DM Sans, Arial, sans-serif;
  font-weight: 400;
  line-height: 148%;
  color: #e02424;
`;

export const Label = styled.span`
  font-family: DM Sans, Inter, Arial, sans-serif;
  font-weight: 400;
  line-height: 128%;
  color: #6b7280;
  margin-bottom: 2%;
`;

export const Input = styled.input`
  margin-top: 1%;
  width: 100%;
  max-width: 100%;
  padding: 1%;
  border: ${props => props?.error ? '1px solid #f8b4b4' : '1px solid #e5e7eb'};
  border-radius: 0.3rem;
  color: #1f2a37;
  overflow: hidden;
  font-family: Inter, DM Sans, Arial, sans-serif;
  font-weight: 400;
  line-height: 144%;
  text-transform: ${props => props?.capitalize ? 'capitalize' : 'none'};

  &:hover {
    border: 1px solid #3b4658;
  }

  &:focus {
    outline: none;
    border: 1px solid #3b4658;
  }

  &::placeholder {
    color: #9ca3af;
  }
`;


export const Title = styled.h1`
    font-family: DM Sans, Inter, Arial, sans-serif;
    font-weight: 500;
    line-height: 128%;
    color: #1f2a37;
    margin-bottom: 2%;
`;

export const Link = styled(RouterLink)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }

    &:-webkit-any-link {
        text-decoration: none;
        cursor: pointer;
    }
`;

export const LinkLabel = styled.span`
    font-family: DM Sans, Inter, Arial, sans-serif;
    font-weight: 400;
    line-height: 128%;
    color: #6b7280;
    margin-bottom: 2%;

    &:hover {
        color: #1f2a37;
        font-weight: 600;
    }
`;

styled(RouterLink)`
  text-align: justify;
  color: #6b7280;
  text-decoration: "none";
  font-weight: 400;
  line-height: 144%;

  &:hover {
    text-decoration: none;
    color: #1f2a37;
    font-weight: 600;
  }

  &:-webkit-any-link {
    text-decoration: none;
    cursor: pointer;
  }
`;
