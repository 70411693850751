import React, { useState } from 'react';
import {
    LandingPageContainer,
    HeaderRow,
    HeaderCol,
    Title,
    Description,
    ContentRow,
    DemoVideoCol,
    DemoVideo,
    FormCol,
    TutorialRow,
    TutorialCol,
} from './LandingPageStyled';
import PlgAlert from '../../../components/plg-alert/PlgAlert';
import Form from '../components/form/Form';
import Tutorial from '../components/tutorial/Tutorial';

const LandingPage = () => {
    const [plgError, setPlgError] = useState(false);


    return (
        <LandingPageContainer>
            <PlgAlert
                app='copycat-importer'
                appName='Copycat Importer'
                error={plgError}
            />
            <HeaderRow>
                <HeaderCol>
                    <Title>Copie qualquer produto da Shopify para o Cartpanda</Title>
                    <Description>IMPORTE PRODUTOS PARA A SUA LOJA COM FACILIDADE</Description>
                </HeaderCol>
            </HeaderRow>
            <ContentRow>
                <DemoVideoCol xs={11} lg={5}>
                    <DemoVideo
                        src='https://www.youtube.com/embed/7S9U_T76Yzc'
                        title='Importe produtos de qualquer loja Shopify para a Cartpanda em segundos com esta nova ferramenta'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        allowFullScreen
                    />
                </DemoVideoCol>
                <FormCol xs={11} lg={5}>
                    <Form
                        setPlgError={setPlgError}
                    />
                </FormCol>
            </ContentRow>
            <TutorialRow>
                <TutorialCol xs={12}>
                    <Tutorial />
                </TutorialCol>
            </TutorialRow>
        </LandingPageContainer>
    );
};

export default LandingPage;