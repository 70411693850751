import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetCurrentUser } from '../hooks/useGetCurrentUser';

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
    const location = useLocation();
    const getCurrentUser = useGetCurrentUser();
    const [user, setUser] = useState({});
    const [forceUpdate, setForceUpdate] = useState(false);
    // add isLoading state which will be used int the AuthorizedPage component
    const [isLoading, setIsLoading] = useState(true); 

    const fetchUser = async () => {
        const { currentUser } = await getCurrentUser();
        setUser(currentUser);
        // set isLoading to false after user data is fetched
        setIsLoading(false);
    };

    useEffect(() => {
        fetchUser();
        window.addEventListener('storage', fetchUser);
        return () => window.removeEventListener('storage', fetchUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (forceUpdate) {
            fetchUser();
            setForceUpdate(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceUpdate]);

    return (
        <UserContext.Provider value={{ user, setUser, isLoading, setForceUpdate }}>
            {children}
        </UserContext.Provider>
    );
};
