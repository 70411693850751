import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Zoom from '@mui/material/Zoom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export const AlertBox = ({ severity, onClose, message, timeout, color, variant, sx, action }) => {
    const severities = {
        error: 'Erro',
        warning: 'Alerta',
        info: 'Info',
        success: 'Sucesso',
    };

    useEffect(() => {
        if (onClose && timeout) {
            setTimeout(() => {
                onClose();
            }, timeout);
        }
    }, [onClose, timeout]);

    return (
        <Row style={{ marginTop: '2%' }}>
            <Col xs={12}>
                <Zoom in={true}>
                    <Alert severity={severity} onClose={onClose} color={color} variant={variant} sx={sx} action={action}>
                        <AlertTitle>{severities[severity]}</AlertTitle>
                        {message}
                    </Alert>
                </Zoom>
            </Col>
        </Row>
    );
};