import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRedirectOnTimeout } from '../../../hooks/useRedirectOnTimeout';
import { Row, Col } from 'react-bootstrap';
import {
    ResetPasswordContainer,
    ErrorMsg,
    Label,
    Input,
    Title,
} from './ResetPasswordStyled';
import {
    PasswordContainer,
    HidePasswordIcon,
    ShowPasswordIcon,
} from '../../../styles/global';
import { Button } from '../../../components/button/Button';
import { Loader } from '../../../components/loader/Loader';
import { AlertBox } from '../../../components/alert-box/AlertBox';
import { useResetPassword } from '../../../hooks/useResetPassword';
import { validatePassword } from '../../../utils/validatePassword';

const ResetPassword = () => {
    const [form, setForm] = useState({
        password: '',
        passwordRepeated: '',
    });
    const [errors, setErrors] = useState({});
    const [errorProcessingMsg, setErrorProcessingMsg] = useState('');
    const [successProcessingMsg, setSuccessProcessingMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRepeated, setShowPasswordRepeated] = useState(false);
    const requiredFields = ['password', 'passwordRepeated'];
    const fields = {
        password: 'Senha',
        passwordRepeated: 'Repita a Senha',
    }
    const resetPassword = useResetPassword();
    const token = new URL(window.location).searchParams.get('token');
    const navigate = useNavigate();
    const redirectOnTimeout = useRedirectOnTimeout();
    // timeout used for success message alert and redirect to /sign-in
    const successTimeout = 5000;
    // timeout used for error message and redirect to /forgot-password
    const errorTimeout = 5000;
    
    if (!token) {
        redirectOnTimeout('/forgot-password', errorTimeout);
        return (
            <ResetPasswordContainer style={{ marginTop: '2%', marginBottom: '2%' }}>
                <Row className="justify-content-center">
                    <Col xs={9}>
                        <AlertBox
                            severity='error'
                            message='Link de redefinição inválido ou já expirado. Envie um novo link de redefinição de senha para o seu email!'
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                    <Col xs={9}>
                        {
                            isLoading ?
                                <Loader /> :
                                <Button
                                    label='Gerar Novo Link'
                                    onClick={() => navigate('/forgot-password')}
                                    theme='primary'
                                    type='button'
                                />
                        }
                    </Col>
                </Row>
            </ResetPasswordContainer>
        );
    }

    const validateRequiredFields = () => {
        const missingFields = requiredFields.filter((field) => !form[field]);

        if (missingFields?.length) {
            const allErrors = { ...errors };

            missingFields.forEach((missingField) => {
                allErrors[missingField] = 'Este campo é obrigatório.';
            });

            setErrors(allErrors);
            setErrorProcessingMsg('Preencha todos os campos obrigatórios.');
            return false;
        }

        if (form.password !== form.passwordRepeated) {
            setErrorProcessingMsg('As senhas estão diferentes.');
            return false;
        }

        const fieldsWithError = requiredFields.filter((field) => !!errors[field]);
        if (fieldsWithError.length > 0) {
            setErrorProcessingMsg(`Corrija os erros dos seguintes campos: ${fieldsWithError.map((field) => fields[field])}`);
            return false;
        }

        return true;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        setForm({
            ...form,
            [name]: value
        });

        if (!value && requiredFields.includes(name)) {
            setErrors({
                ...errors,
                [name]: `O campo ${fields[name]} é obrigatório.`,
            });
            return;
        }
        
        if (name === 'password' && !validatePassword(value)) {
            setErrors({
                ...errors,
                [name]: 'A senha deve possuir ao menos 8 caracteres, 1 letra minúscula, ' +
                '1 letra maiúscula, 1 número e 1 caractere especial. A senha pode ter no máximo 72 caracteres.',
            });
            return;
        }
    
        if (name === 'passwordRepeated' && form?.password !== value) {
            setErrors({
                ...errors,
                [name]: 'As senhas estão diferentes.',
            });
            return;
        }

        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        if (validateRequiredFields()) {
            const { status } = await resetPassword({
                token,
                password: form.password,
            });
            setIsLoading(false);

            if (status === 200) {
                setSuccessProcessingMsg('Senha redefinida com sucesso! Você já pode entrar novamente.');
                redirectOnTimeout('/sign-in', successTimeout);
                return;
            }

            setErrorProcessingMsg('Link de redefinição inválido ou já expirado. Envie um novo link de redefinição de senha para o seu email!');
            redirectOnTimeout('/forgot-password', errorTimeout);
        } else {
            setIsLoading(false);
        }
    };

    return (
        <ResetPasswordContainer style={{ marginTop: '2%', marginBottom: '2%' }}>
            <Row className="justify-content-center"  style={{ marginTop: '3%' }}>
                <Col xs={9}>
                    <Title>
                        Redefinir Senha
                    </Title>
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                <Col xs={9}>
                    <Label>Senha Nova</Label>
                    <PasswordContainer>
                        <Input
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            value={form.password}
                            onChange={handleChange}
                        />
                        { showPassword ?
                            <HidePasswordIcon onClick={() => setShowPassword(false)}/> :
                            <ShowPasswordIcon onClick={() => setShowPassword(true)}/>
                        }
                    </PasswordContainer>
                    { errors?.password && (
                        <ErrorMsg>{errors.password}</ErrorMsg>
                    )}
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                <Col xs={9}>
                    <Label>Repita a Senha</Label>
                    <PasswordContainer>
                        <Input
                            name='passwordRepeated'
                            type={showPasswordRepeated ? 'text' : 'password'}
                            value={form.passwordRepeated}
                            onChange={handleChange}
                        />
                        { showPasswordRepeated ?
                            <HidePasswordIcon onClick={() => setShowPasswordRepeated(false)}/> :
                            <ShowPasswordIcon onClick={() => setShowPasswordRepeated(true)}/>
                        }
                    </PasswordContainer>
                    { errors?.passwordRepeated && (
                        <ErrorMsg>{errors.passwordRepeated}</ErrorMsg>
                    )}
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                <Col xs={9}>
                    {
                        isLoading ?
                            <Loader /> :
                            <Button
                                label='Redefinir'
                                onClick={handleResetPassword}
                                theme='primary'
                                type='button'
                            />
                    }
                </Col>
            </Row>
            {errorProcessingMsg &&
                <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                    <Col xs={9}>
                        <AlertBox
                            severity='error'
                            message={errorProcessingMsg}
                            onClose={() => setErrorProcessingMsg('')}
                            timeout={errorTimeout}
                        />
                    </Col>
                </Row>
            }
            {successProcessingMsg &&
                <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                    <Col xs={9}>
                        <AlertBox
                            severity='success'
                            message={successProcessingMsg}
                            onClose={() => setSuccessProcessingMsg('')}
                            timeout={successTimeout}
                        />
                    </Col>
                </Row>
            }
        </ResetPasswordContainer>
    );
};

export default ResetPassword;
