import React, { createContext, useEffect, useState } from 'react';
import checkoutService from '../services/checkoutService';

export const CartContext = createContext({});

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState({});
    // add isLoading state which will be used int the AuthorizedPage component
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCart = () => {
            const userCart = checkoutService.getCart();
            setCart(userCart);
            // set isLoading to false after cart data is fetched
            setIsLoading(false);
        }
        fetchCart();
        window.addEventListener('storage', fetchCart);
        return () => window.removeEventListener('storage', fetchCart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CartContext.Provider value={{ cart, setCart, isLoading }}>
            {children}
        </CartContext.Provider>
    );
};
