import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Link as RouterLink } from 'react-router-dom';

export const FooterBorderRow = styled(Row)`
  margin-top: 0.5%;
`;

export const FooterRow = styled(Row)`
  margin: 0.5em 0.25em 0.25em 0.25em;
`;

export const FooterBorderCol = styled(Col)``;

export const FooterCol = styled(Col)`
  text-align: center;
  align-items: top;
`;

export const LogoIconCol = styled(Col)``;

export const LogoIcon = styled.img`
  width: auto;
  height: 3rem;
  max-height: 100%;
  max-width: 100%;

  @media screen and (max-width: 991px) {
    width: 3.5rem;
    height: 3.5rem;
    object-fit: fill;
  }
`;

export const BrandNameIcon = styled.img`
  min-height: 3.5rem;
  min-width: 3.5rem;
  width: auto;
  height: 3rem;
  max-height: 100%;
  max-width: 100%;

  @media screen and (max-width: 991px) {
    object-fit: fill;
  }
`;

export const SocialMediaCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.25em;
`;

export const SocialMediaIconContainer = styled.div`
  margin: 0 0.5em;
`;

export const SocialMediaIcon = styled.img`
  height: 2rem;
  width: 2rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  max-height: 100%;
  max-width: 100%;

  @media screen and (max-width: 991px) {
    object-fit: fill;
  }
`;

export const Doc = styled.div``;

export const FooterBorder = styled.div`
  margin: 3% 0 1% 0;
  border-top: 1px solid #cdcdcd;
`;

export const MetadataContainer = styled.div`
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const RegisteredTrademark = styled.span`
  text-align: justify;
  line-height: 1.5rem;
  font-size: 0.8em;
  color: #3b4658;
`;

export const Description = styled.span`
  color: #1f2a37;
  text-align: justify;
  font-family: DM Sans, Arial;
  font-weight: 500;
`;

export const DocLink = styled(RouterLink)`
  text-align: justify;
  color: #6b7280;
  text-decoration: "none";
  font-weight: 400;
  line-height: 144%;

  &:hover {
    text-decoration: none;
    color: #1f2a37;
    font-weight: 600;
    font-size: 1.015rem;
    letter-spacing: 0.01rem;
  }

  &:-webkit-any-link {
    text-decoration: none;
    cursor: pointer;
  }
`;

export const Contact = styled.span`
  color: #1f2a37;
`;

export const EmailLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #6b7280;
  line-height: 144%;

  &:hover {
    text-decoration: none;
    color: #1f2a37;
    font-weight: 600;
    font-size: 1.015rem;
    letter-spacing: 0.01rem;
  }
`;

export const WhatsappLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #6b7280;
  line-height: 144%;

  &:hover {
    text-decoration: none;
    color: #1f2a37;
    font-weight: 600;
    font-size: 1.015rem;
    letter-spacing: 0.02rem;
  }
`;

export const SocialMediaLink = styled.a``;

export const HomeLink = styled(RouterLink)``;