import { useNavigate } from "react-router-dom";

export function useRedirectOnTimeout() {
    const navigate = useNavigate();

    return (to, timeout = 0) => {
        setTimeout(() => {
            navigate(to);
        }, timeout);
    }
}