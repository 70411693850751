import styled from 'styled-components';
import { Badge } from 'react-bootstrap';

export const CheckoutPageContainer = styled.div`
    //background-color: #3b4658;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 320px;
    height: auto;
    min-height: 70vh;
    padding: 48px 20px;
`;

export const CheckoutPageTitle = styled.h1`
    color: #3b4658;
    font-family: DM Sans, Arial;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
`;

export const CheckoutPlans = styled.div`
    display: flex;
    //box-shadow: 2px 2px 5px 0 rgba(60, 66, 87, .08), 0 1px 1px 0 rgba(0, 0, 0, .12);
    //box-shadow: 0 0 0 2px rgba(60, 66, 87, .08);
`;

export const CheckoutPlanDetails = styled.div`
    position: relative;
    border-radius: 6px;
    border: 3px solid #0000001f;
    padding: 32px 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-width: 19rem;
    width: 19rem;
    background-color: #ffffff;
    margin: 1%;

    &:hover {
      border: 3px solid #3b4658;
    }
  
    // &:not(:last-child) {
    //   border-top-right-radius: ${(props) => props?.selected ? '6px' : '0px' };
    //   border-bottom-right-radius: ${(props) => props?.selected ? '6px' : '0px' };
    //   border-right:  ${(props) => props?.selected ? '3px solid #3b4658' : '3px solid #f3f3f3' };
    // }
    //
    // &:not(:first-child) {
    //     border-bottom-left-radius:  ${(props) => props?.selected ? '6px' : '0px' };
    //     border-top-left-radius: ${(props) => props?.selected ? '6px' : '0px' };
    // }
`;

export const PlanName = styled.div`
    color: #7F7F7F;
    font-size: large;
    margin-top: 8px;
    text-align: center;
`;

export const PlanPrice = styled.div`
    color: #000000;
    font-size: xx-large;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 8px;
    text-align: center;
`;

export const PlanDuration = styled.div`
    color: #969696;
    font-size: smaller;
    text-align: center;
    margin-bottom: 16px;
`;

export const SmallText = styled.span`
    margin-top: 10%;
    color: #969696;
    font-size: smaller;
    text-align: center;
    margin-bottom: 16px;
`;

export const BadgeStyled = styled(Badge)`
    position: absolute;
    top: 3%;
    background: #50C878 !important;
    text-transform: uppercase;
    font-size: 1rem;
`;