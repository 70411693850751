import React, { useContext, useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import {
    SignInContainer,
    ErrorMsg,
    Label,
    LinkLabel,
    Input,
    Title,
    Link,
} from './SignInStyled';
import {
    PasswordContainer,
    HidePasswordIcon,
    ShowPasswordIcon,
} from '../../../styles/global';
import { Button } from '../../../components/button/Button';
import { Loader } from '../../../components/loader/Loader';
import { AlertBox } from '../../../components/alert-box/AlertBox';
import { useSignIn } from '../../../hooks/useSignIn';
import { useCustomCheckout } from '../../../hooks/useCustomCheckout';
import { validateEmail } from '../../../utils/validateEmail';
import { useGetCurrentUser } from '../../../hooks/useGetCurrentUser';
import { UserContext } from '../../../contexts/UserContext';
import checkoutService from '../../../services/checkoutService';
import { formatPhoneE164BR } from '../../../utils/formatPhoneE164';

const SignIn = () => {
    const navigate = useNavigate();
    const getCurrentUser = useGetCurrentUser();
    const { setUser } = useContext(UserContext);
    const [form, setForm] = useState({
        email: '',
        password: '',
        loading: false,
    });
    const [errors, setErrors] = useState({});
    const [errorProcessingMsg, setErrorProcessingMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const requiredFields = ['email', 'password'];
    const fields = {
        email: 'E-mail',
        password: 'Senha',
    };
    const signIn = useSignIn();
    const redirectToCheckout = useCustomCheckout();
    
    useEffect(() => {
        getCurrentUser().then(({ status, currentUser }) => {
            if (status === 200 && currentUser?.email) {
                navigate('/profile');
            }
        });
    });
    
    const validateRequiredFields = () => {
        const missingFields = requiredFields.filter((field) => !form[field]);

        if (missingFields?.length) {
            const allErrors = { ...errors };

            missingFields.forEach((missingField) => {
                allErrors[missingField] = 'Este campo é obrigatório.'
            });

            setErrors(allErrors);
            setErrorProcessingMsg('Preencha todos os campos obrigatórios.');
            return false;
        }

        const fieldsWithError = requiredFields.filter((field) => !!errors[field]);
        if (fieldsWithError.length > 0) {
            setErrorProcessingMsg(`Corrija os erros dos seguintes campos: ${fieldsWithError.map((field) => fields[field])}`);
            return false;
        }

        return true;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        setForm({
            ...form,
            [name]: value
        });

        if (!value && requiredFields.includes(name)) {
            setErrors({
                ...errors,
                [name]: `O campo ${fields[name]} é obrigatório.`,
            });
            return;
        }
    
        if (name === 'email') {
            const userEmail = value.toLowerCase();
        
            if (!validateEmail(userEmail)) {
                setErrors({
                    ...errors,
                    email: 'Endereço de e-mail inválido'
                });
            
                return;
            }
        }

        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleSignIn = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        if (validateRequiredFields()) {
            const { status, data } = await signIn({ email: form.email, password: form.password });
            setIsLoading(false);
    
            if (status === 200) {
                const { currentUser } = await getCurrentUser();
                setUser(currentUser);
                const userData = {
                    id: currentUser.id,
                    email: currentUser.email,
                    phone: formatPhoneE164BR(currentUser.whatsapp),
                }

                TagManager.dataLayer({
                    dataLayer: {
                        event: 'sign_in',
                        user: userData,
                    }
                });

                const latestPlanChosen = checkoutService.getLatestPlanChosen();
    
                if (latestPlanChosen?.id) {
                    redirectToCheckout(latestPlanChosen, currentUser).then(({ status, data }) => {
                        if (status !== 200) {
                            setErrorProcessingMsg('Erro ao processar o carrinho, tente novamente mais tarde.');
                            return;
                        }
                        const { redirectUrl } = data;
                        window.open(redirectUrl, '_blank');
                    });
                    return;
                }
    
                return navigate('/profile');
            }

            const errorMsg = (status === 401 || status === 403) && data?.message ?
                data.message :
                'Houve um erro ao processar seu cadastro. Tente novamente mais tarde.';

            setErrorProcessingMsg(errorMsg);
        } else {
            setIsLoading(false);
        }
    };

    return (
        <SignInContainer style={{ marginTop: '2%' }}>
            <Row className="justify-content-center"  style={{ marginTop: '3%' }}>
                <Col xs={9}>
                    <Title>
                        Entrar
                    </Title>
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                <Col xs={9}>
                    <Label>E-mail</Label>
                    <Input
                        name='email'
                        autoComplete='email'
                        type='text'
                        value={form.email}
                        onChange={handleChange}
                        placeholder='johnsnow@gmail.com'
                    />
                    { errors?.email && (
                        <ErrorMsg>{errors.email}</ErrorMsg>
                    )}
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                <Col xs={9}>
                    <Label>Senha</Label>
                    <PasswordContainer>
                        <Input
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            value={form.password}
                            onChange={handleChange}
                        />
                        { showPassword ?
                            <HidePasswordIcon onClick={() => setShowPassword(false)}/> :
                            <ShowPasswordIcon onClick={() => setShowPassword(true)}/>
                        }
                    </PasswordContainer>
                    { errors?.password && (
                        <ErrorMsg>{errors.password}</ErrorMsg>
                    )}
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '2%' }}>
                <Col xs={9}>
                    <Link to='/sign-up'>
                        <LinkLabel>Usuário Novo? Cadastre-se!</LinkLabel>
                    </Link>
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '1%' }}>
                <Col xs={9}>
                    <Link to='/forgot-password'>
                        <LinkLabel>Esqueceu a senha?</LinkLabel>
                    </Link>
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '3%', marginBottom: '2%' }}>
                <Col xs={9}>
                    {
                        isLoading ?
                            <Loader /> :
                            <Button
                                label='Entrar'
                                onClick={handleSignIn}
                                theme='primary'
                                type='button'
                            />
                    }
                </Col>
            </Row>
            {errorProcessingMsg &&
                <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                    <Col xs={9}>
                        <AlertBox
                            severity='error'
                            message={errorProcessingMsg}
                            onClose={() => setErrorProcessingMsg('')}
                            timeout={5000}
                        />
                    </Col>
                </Row>
            }
        </SignInContainer>
    );
};

export default SignIn;
