import React from 'react';
import {
    TipContainer,
    TipItem,
    TipList,
    TipTitle,
    TipText,
    ContainerStyled,
    TipImg,
    TipRow,
    TipCol,
} from './TipCardStyled';

const TipCard = ({ title, items = [] }) => {
    const getItem = (item) => {
        const isLink = item.link && item.link.href && item.link.description
        return (isLink &&
            <ContainerStyled href={item.link.href} target={item.link.target || '_blank'}>
                <u>{item.link.description}</u>
            </ContainerStyled>) || item.text;
    }

    return (
        <TipContainer>
            <TipRow>
                <TipCol xs={12}>
                    <TipTitle>{title}</TipTitle>
                </TipCol>
            </TipRow>
            <TipRow>
                <TipCol xs={12}>
                    <TipList>
                        {items.map((item, index) => (
                            <TipItem key={index}>
                                <TipText>
                                    {getItem(item)}
                                </TipText>
                                {   item.image?.src &&
                                    <TipImg
                                        src={item.image.src}
                                        alt={item.image.alt}
                                        height={item.image.height}
                                    />
                                }
                            </TipItem>
                        ))}
                    </TipList>
                </TipCol>
            </TipRow>
        </TipContainer>
    );
}

export default TipCard;