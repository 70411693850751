import styled from 'styled-components';

export const FeaturesListStyled = styled.ul`
  list-style-type: none;
  color: ${(props) => props?.color ? props.color : '#4a1d96'};
  font-weight: 400;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: ${(props) => props?.listDirection === 'vertical' ? 'column' : 'row'};
  justify-content: ${(props) => props?.listDirection === 'vertical' ? 'center' : 'space-between'};
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 10rem;
`;

export const FeaturesListItem = styled.li`
    color: ${(props) => props?.color ? props.color : '#4a1d96'};

  &:before {
    content: '✓ ';
    color: ${(props) => props?.color ? props.color : '#4a1d96'};
    font-family: Inter, DM Sans, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 148%;
  }
`;

export const FeaturesListItemText = styled.span`
  font-family: Inter, DM Sans, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 148%;
  color: ${(props) => props?.color ? props.color : '#4a1d96'};

  @media (max-width: 991px) {
    font-size: 2vw;
  }
`;