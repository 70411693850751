import { useContext } from 'react';
import TagManager from 'react-gtm-module';
import paymentsApiInstance from '../services/paymentsApi';
import { useApiInterceptor } from './useApiInterceptor';
import { CartContext } from '../contexts/CartContext';
import checkoutService from '../services/checkoutService';
import { formatPhoneE164BR } from '../utils/formatPhoneE164';

const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export function useCustomCheckout() {
    const paymentsApi = useApiInterceptor(paymentsApiInstance);
    const { setCart } = useContext(CartContext);

    return async (plan, user) => {
        try {
            const successUrl = `${FRONTEND_URL}/checkout/success?planId=${plan.id}&userId=${user.id}`;
            const cancelUrl = `${FRONTEND_URL}/checkout/cancel?planId=${plan.id}&userId=${user.id}`;
            
            const validateStatus = () => true;
            const { status, data } =
                await paymentsApi.post('/checkout', {
                    planId: plan.id,
                    userId: user.id,
                    email: user.email,
                    successUrl,
                    cancelUrl,
                }, { validateStatus });

            // If request is successful the user will be redirect to stripe checkout.
            // Therefore, we can clear the cart data to avoid users being redirected to
            // the checkout page every time they sign in to the app if they don't buy the plan.
            if (status === 200) {
                const userData = {
                    id: user.id,
                    email: user.email,
                    phone: formatPhoneE164BR(user.whatsapp),
                }
                checkoutService.setCheckoutData(plan.id, userData);
                // As the setCheckoutPlan method already remove the plan from the cart, this resetCart method call is no longer necessary
                // checkoutService.resetCart();
                setCart(checkoutService.getCart());

                // Begin checkout tag configuration: 
                // Source: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#begin_checkout-gtm
                TagManager.dataLayer({
                    dataLayer: {
                        ecommerce: null
                    }
                });
                TagManager.dataLayer({
                    dataLayer: {
                        event: "begin_checkout",
                        user: userData,
                        ecommerce: {
                            currency: plan.currency,
                            value: plan.price,
                            items: [{
                                item_id: plan.id,
                                item_name: plan.name,
                                index: plan.idx,
                                item_list_id: plan.appName,
                                item_list_name: plan.appDisplayName,
                                item_variant: plan.name,
                                price: plan.price,
                                quantity: 1,
                            }]
                        }
                    }
                });
            }

            return { status, data };
        } catch (error) {
            // any network error will fall under this block
            return { status: 500 , data: null };
        }
    };
}