import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
    ForgotPasswordContainer,
    ErrorMsg,
    Label,
    Input,
    Title,
} from './ForgotPasswordStyled';
import { Button } from '../../../components/button/Button';
import { Loader } from '../../../components/loader/Loader';
import { AlertBox } from '../../../components/alert-box/AlertBox';
import { useForgotPassword } from '../../../hooks/useForgotPassword';
import { useRedirectOnTimeout } from '../../../hooks/useRedirectOnTimeout';
import { validateEmail } from '../../../utils/validateEmail';

const ForgotPassword = () => {
    const [form, setForm] = useState({
        email: '',
    });
    const [errors, setErrors] = useState({});
    const [errorProcessingMsg, setErrorProcessingMsg] = useState('');
    const [successProcessingMsg, setSuccessProcessingMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // state used to control if the 'send-link' button should be enable or not according to the rate limit settings
    const [disabled, setDisabled] = useState(false);
    const requiredFields = ['email'];
    const fields = {
        email: 'E-mail',
    }
    const forgotPassword = useForgotPassword();
    const redirectOnTimeout = useRedirectOnTimeout();
    // timeout used for success message alert and redirect to /sign-in
    const successTimeout = 5000;
    // timeout used for error message and to reactivate the send email button
    const errorTimeout = 30000;

    const validateRequiredFields = () => {
        const missingFields = requiredFields.filter((field) => !form[field]);

        if (missingFields?.length) {
            const allErrors = { ...errors };

            missingFields.forEach((missingField) => {
                allErrors[missingField] = 'Este campo é obrigatório.';
            });

            setErrors(allErrors);
            setErrorProcessingMsg('Preencha todos os campos obrigatórios.');
            return false;
        }

        const fieldsWithError = requiredFields.filter((field) => !!errors[field]);
        if (fieldsWithError.length > 0) {
            setErrorProcessingMsg(`Corrija os erros dos seguintes campos: ${fieldsWithError.map((field) => fields[field])}`);
            return false;
        }

        return true;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        setForm({
            ...form,
            [name]: value
        });

        if (!value && requiredFields.includes(name)) {
            setErrors({
                ...errors,
                [name]: `O campo ${fields[name]} é obrigatório.`,
            });
            return;
        }

        if (name === 'email') {
            const userEmail = value.toLowerCase();

            if (!validateEmail(userEmail)) {
                setErrors({
                    ...errors,
                    email: 'E-mail inválido.',
                });

                return;
            }
        }

        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleForgotPassword = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        if (validateRequiredFields()) {
            setDisabled(true);

            const { status } = await forgotPassword({
                email: form.email,
            });
            setIsLoading(false);

            if (status === 200) {
                setSuccessProcessingMsg('Se o e-mail estiver cadastrado, você receberá um link de recuperação de senha. Confira também a caixa de spam.');
                redirectOnTimeout('/sign-in', successTimeout);
                return;
            }

            setErrorProcessingMsg('Houve um erro ao processar sua solicitação. Entre em contato com o suporte ou tente novamente mais tarde.');
            setTimeout(() => {
                setDisabled(false);
            }, errorTimeout);
        } else {
            setIsLoading(false);
        }
    };

    return (
        <ForgotPasswordContainer style={{ marginTop: '2%', marginBottom: '2%' }}>
            <Row className="justify-content-center"  style={{ marginTop: '3%' }}>
                <Col xs={9}>
                    <Title>
                        Redefinir Senha
                    </Title>
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                <Col xs={9}>
                    <Label>E-mail</Label>
                    <Input
                        name='email'
                        autoComplete='email'
                        type='text'
                        value={form.email}
                        onChange={handleChange}
                        placeholder='johnsnow@gmail.com'
                    />
                    { errors?.email && (
                        <ErrorMsg>{errors.email}</ErrorMsg>
                    )}
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '3%', marginBottom: '2%' }}>
                <Col xs={9}>
                    {
                        isLoading ?
                            <Loader /> :
                            <Button
                                label='Enviar Link'
                                onClick={handleForgotPassword}
                                theme='primary'
                                type='button'
                                disabled={disabled}
                            />
                    }
                </Col>
            </Row>
            {errorProcessingMsg &&
                <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                    <Col xs={9}>
                        <AlertBox
                            severity='error'
                            message={errorProcessingMsg}
                            onClose={() => setErrorProcessingMsg('')}
                            timeout={errorTimeout}
                        />
                    </Col>
                </Row>
            }
            {successProcessingMsg &&
                <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                    <Col xs={9}>
                        <AlertBox
                            severity='success'
                            message={successProcessingMsg}
                            onClose={() => setSuccessProcessingMsg('')}
                            timeout={successTimeout}
                        />
                    </Col>
                </Row>
            }
        </ForgotPasswordContainer>
    );
};

export default ForgotPassword;
