import React from 'react';
import TagManager from 'react-gtm-module';
import { Container } from 'react-bootstrap';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {
    FooterBorderRow,
    FooterRow,
    FooterBorderCol,
    FooterCol,
    LogoIconCol,
    BrandNameIcon,
    RegisteredTrademark,
    DocLink,
    Contact,
    FooterBorder,
    Doc,
    SocialMediaCol,
    SocialMediaIconContainer,
    SocialMediaIcon,
    LogoIcon,
    EmailLink,
    WhatsappLink,
    SocialMediaLink,
    HomeLink,
} from './FooterStyled';
import logoIcon from '../../assets/logo.svg';
import brandNameIcon from '../../assets/dark-title.svg';
import youtubeIcon from '../../assets/youtube-black-background-icon.svg';
import instagramIcon from '../../assets/instagram-black-background-icon.svg';
import tiktokIcon from '../../assets/tiktok-black-background-icon.svg';


const Footer = () => {
    return (
        <>
            <FloatingWhatsApp 
                phoneNumber={'+5531998002089'}
                accountName={'Copycat Apps'}
                avatar={logoIcon}
                statusMessage={'Conta comercial'}
                chatMessage={'Olá! Tudo bem? 😁 \nComo podemos lhe ajudar?'}
                placeholder={'Mensagem'}
                messageDelay={1}
                onSubmit={() => {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'whatsapp_message'
                        }
                    });
                }}
            />
            <FooterBorderRow>
                <FooterBorderCol>
                    <FooterBorder />
                </FooterBorderCol>
            </FooterBorderRow>
            <Container>
                <FooterRow>
                    <FooterCol xs={12} xl={3}>
                        <FooterRow>
                            <LogoIconCol>
                                <HomeLink to='/'>
                                    <LogoIcon src={logoIcon} alt='Copycat"s logo'/>
                                    <BrandNameIcon src={brandNameIcon} alt='Copycat"s brand name'/>
                                </HomeLink>
                            </LogoIconCol>
                        </FooterRow>
                    </FooterCol>
                    <FooterCol xs={12} xl={4}>
                        <FooterRow>
                            <FooterCol>
                                <Contact>
                                    <i>
                                        <EmailLink href="mailto:suporte@copycat.intellabs.com.br" target='_blank'>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                            <span> suporte@copycat.intellabs.com.br</span>
                                        </EmailLink>
                                    </i>
                                </Contact>
                            </FooterCol>
                        </FooterRow>
                        <FooterRow>
                            <FooterCol>
                                <Contact>
                                    <i>
                                        <WhatsappLink href="https://wa.me/5531998002089?text=Ol%C3%A1%2C+Copycat%21+Tenho+uma+d%C3%BAvida." target='_blank'>
                                            <FontAwesomeIcon icon={faWhatsapp} />
                                            <span> (31) 9 9800-2089</span>
                                        </WhatsappLink>
                                    </i>
                                </Contact>
                            </FooterCol>
                        </FooterRow>
                    </FooterCol>
                    <FooterCol xs={12} xl={2}>
                        <FooterRow>
                            <FooterCol>
                                <Doc>
                                    <i>
                                        <DocLink to='/terms'>Termos de Uso</DocLink>
                                    </i>
                                </Doc>
                            </FooterCol>
                        </FooterRow>
                        <FooterRow>
                            <FooterCol>
                                <Doc>
                                    <i>
                                        <DocLink to='/privacy'>Privacidade</DocLink>
                                    </i>
                                </Doc>
                            </FooterCol>
                        </FooterRow>
                    </FooterCol>
                    <FooterCol xs={12} xl={3}>
                        <FooterRow>
                            <SocialMediaCol>
                                <SocialMediaLink href='https://www.youtube.com/@copycat-apps' target='_blank'>
                                    <SocialMediaIconContainer>
                                        <SocialMediaIcon src={youtubeIcon} alt='Youtube"s logo' />
                                    </SocialMediaIconContainer>
                                </SocialMediaLink>
                                <SocialMediaLink href='https://www.instagram.com/copycat.apps' target='_blank'>
                                    <SocialMediaIconContainer>
                                        <SocialMediaIcon src={instagramIcon} alt='Instagram"s logo' />
                                    </SocialMediaIconContainer>
                                </SocialMediaLink>
                                <SocialMediaLink href='https://www.tiktok.com/@copycat.apps' target='_blank'>
                                    <SocialMediaIconContainer>
                                        <SocialMediaIcon src={tiktokIcon} alt='Tik-tok"s logo' />
                                    </SocialMediaIconContainer>
                                </SocialMediaLink>
                            </SocialMediaCol>
                        </FooterRow>
                    </FooterCol>
                </FooterRow>
                <FooterRow style={{ margin: '3% 0', justifyContent: 'center', textAlign: 'center' }}>
                    <FooterCol xs={12}>
                        <FooterRow>
                            <FooterCol>
                                <RegisteredTrademark>
                                    © 2023 <span className='notranslate'> Copycat</span>. Todos os direitos reservados.
                                </RegisteredTrademark>
                            </FooterCol>
                        </FooterRow>
                    </FooterCol>
                </FooterRow>
            </Container>
        </>
    );
};

export default Footer;