import React from 'react';

const UsageWarning = ({usage, tier, tierLimitReached}) => {
    const validUsage = usage !== '' && tier !== '';
    return validUsage && (
        <div>
            <p style={{ color: tierLimitReached ? 'red' : '' }}>
                {`Usos restantes: ${(!tierLimitReached ? (isNaN(parseInt(tier)) ? tier : tier - usage) : '0 - Limite esgotado')}` }
            </p>
        </div>
    )
};

export default UsageWarning;