import styled from 'styled-components';

export const DocContainer = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
`;

export const DocFrame = styled.iframe`
    width: 100%;
    max-width: 100%;
    height: 62.5rem;
    max-height: 100%;
`;