import usersApi from '../services/usersApi';
import TokenService from '../services/tokenService';
import { useSignOut } from './useSignOut';

export function useApiInterceptor(apiInstance) {
    const signOut = useSignOut();

    const refreshToken = async (originalConfig) => {
        const refreshToken = TokenService.getLocalRefreshToken();

        if (!refreshToken) {
            console.log('[refreshToken] removing tokens. refreshToken: ', !!refreshToken);
            // if the refresh token is invalid, the user has to be logged out
            signOut();
            return { status: 403, data: 'Login necessário. Entre novamente!' };
        }

        const { status, data } = await usersApi.post('/auth/refresh-token', { refreshToken }, {
            validateStatus: () => true,
        });

        if (status !== 200) {
            console.log('[refreshToken] removing tokens. status: ', status);
            // if the refresh token is invalid, the user has to be logged out
            signOut();
            return { status, data };
        }

        const { accessToken } = data;
        TokenService.updateLocalAccessToken(accessToken);
        console.log('[refreshToken] adding tokens. accessToken: ', !!accessToken);
        originalConfig.headers['x-access-token'] = accessToken;
        console.log('-------------', !!originalConfig?.headers['x-access-token']);

        return { status, data };
    };

    if (!apiInstance.customRequestInterceptor) {
        apiInstance.customRequestInterceptor = apiInstance.interceptors.request.use((config) => {
            const token = TokenService.getLocalAccessToken();

            if (token) {
                config.headers['x-access-token'] = token;
            }

            return config;
        }, (error) => {
            return Promise.reject(error);
        }
        );
    }

    if (!apiInstance.customResponseInterceptor) {
        apiInstance.customResponseInterceptor = apiInstance.interceptors.response.use(async (res) => {
            const originalConfig = res.config;

            const isUsersApiAuthEndpoint = (apiInstance === usersApi && originalConfig.url.includes('/auth'));
            // Access Token was expired
            if (res?.status === 401 && !originalConfig._retry && !isUsersApiAuthEndpoint) {
                originalConfig._retry = true;

                try {
                    const { status, data } = await refreshToken(originalConfig);

                    if (status !== 200) {
                        // already removed expired tokens in refreshToken()
                        return { status, data };
                    }

                    return apiInstance(originalConfig);
                } catch (error) {
                    console.log('removing tokens. status: ', 500);
                    // if the refresh token is invalid, the user has to be logged out
                    signOut();
                    return { status: 500 };
                }
            }
            return res;
        }, (error) => {
            return Promise.reject(error);
        }
        );
    }

    return apiInstance;
}