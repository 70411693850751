import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useApps } from '../hooks/useApps';

export const AppsContext = createContext({});

export const AppsProvider = ({ children }) => {
    const { user } = useContext(UserContext);
    const [apps, setApps] = useState([]);
    const [navApps, setNavApps] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [forceUpdate, setForceUpdate] = useState(false);
    const listApps = useApps();

    useEffect(() => {
        const setAppsList = async () => {
            setIsLoading(true);
            const { status, data } = await listApps();

            if (status === 200 && data?.apps?.length) {
                setApps(data.apps);
            }
            setIsLoading(false);
        };

        setAppsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const setAppsList = async () => {
            setIsLoading(true);
            const { status, data } = await listApps();

            if (status === 200 && data?.apps?.length) {
                setApps(data.apps);
            }
            setIsLoading(false);
        };

        if (forceUpdate) setAppsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceUpdate]);

    useEffect(() => {
        const setNavAppsList = () => {
            const auxNavApps = [];
            apps.forEach((app) => {
                const { 
                    displayName, 
                    firstAccessLinks: [
                        { url: plansUrl },
                        { url: appUrl },
                    ] 
                } = app;
                auxNavApps.push( { displayName, plansUrl, appUrl } );
            });
            setNavApps(auxNavApps);
        }

        if (apps.length) setNavAppsList();
    }, [apps]);

    return (
        <AppsContext.Provider value={{ apps, setApps, isLoading, setForceUpdate, navApps }}>
            {children}
        </AppsContext.Provider>
    );
};