import React from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import NavBar from './components/navbar/NavBar';
import Footer from './components/footer/Footer';
import { UserProvider } from './contexts/UserContext';
import { AppsProvider } from './contexts/AppsContext';
import { SigningOutProvider } from './contexts/SigningOutContext';
import { CartProvider } from "./contexts/CartContext";

function App() {
	const tagManagerArgs = {
		gtmId: 'GTM-W64RT7B',
	}

	TagManager.initialize(tagManagerArgs);

	return (
		<div className='app'>
			<BrowserRouter>
				<SigningOutProvider>
					<UserProvider>
						<AppsProvider>
							<CartProvider>
								<NavBar/>
								<Routes />
								<Footer />
							</CartProvider>
						</AppsProvider>
					</UserProvider>
				</SigningOutProvider>
			</BrowserRouter>
		</div>
	);
}

export default App;
