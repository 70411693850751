import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useHandleRedirect } from '../../hooks/useHandleRedirect';
import { useAutomaticScroll } from '../../hooks/useAutomaticScroll';
// import Box from '@mui/material/Box';
import Logo from '../../assets/logo.svg';
import Title from '../../assets/light-title.svg';
import {
    ContainerStyled,
    NavbarStyled,
    NavStyled,
    NavDropdownStyled,
    // Link,
    // CounterStyled,
} from './NavBarStyled';
import { Button } from '../button/Button';
import { useSignOut } from '../../hooks/useSignOut';
import { UserContext } from '../../contexts/UserContext';
import { AppsContext } from '../../contexts/AppsContext';
import { SigningOutContext } from '../../contexts/SigningOutContext';
import { CartContext } from '../../contexts/CartContext';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';



const NavBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleRedirect = useHandleRedirect();
    const automaticScroll = useAutomaticScroll();
    const [plans, setPlans] = useState([]);
    const { user, setUser } = useContext(UserContext);
    const { navApps } = useContext(AppsContext);
    const { isSigningOut, setIsSigningOut } = useContext(SigningOutContext);
    const { cart, setCart } = useContext(CartContext);
    const signOut = useSignOut();

    useEffect(() => {
        automaticScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        const { plans: cartPlans } = cart;

        if (cartPlans?.length >= 0) setPlans(cartPlans);
        // eslint-disable-next-line
    }, [JSON.stringify(cart), cart, setCart, cart?.plans, cart?.plans?.length, plans]);

    useEffect(() => {
        if (isSigningOut) {
            setUser({});
            navigate('/sign-in');
            setIsSigningOut(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSigningOut]);


    const handleSignOut = (event) => {
        event.preventDefault();
        signOut();
    };

    return (
        <NavbarStyled sticky='top' collapseOnSelect expand='md' className='bg-body-tertiary navbar-dark'>
            <ContainerStyled>
                <NavbarStyled.Brand>
                    <NavStyled.Link as={Link} to='/' eventKey='brand-home'>
                        <img
                            alt=''
                            src={Logo}
                            width='auto'
                            height='50'
                            className='d-inline-block align-top'
                        />{' '}
                        <img
                            alt=''
                            src={Title}
                            width='auto'
                            height='50'
                            className='d-inline-block align-top'
                        />
                    </NavStyled.Link>
                </NavbarStyled.Brand>
                <NavbarStyled.Toggle aria-controls='responsive-navbar-nav' />
                <NavbarStyled.Collapse id='responsive-navbar-nav'>
                    <NavStyled className='me-auto'>
                        <NavStyled.Link as={Link} to='/' eventKey='link-home'>Início</NavStyled.Link>
                        {
                            !!navApps.length &&
                            <>
                                <NavDropdownStyled title='Apps' id='collasible-nav-dropdown'>
                                    {navApps?.map((app, index) => (
                                        !!app?.appUrl &&
                                        <NavDropdownStyled.Item
                                            onClick={() => handleRedirect(app?.appUrl)}
                                            eventKey={`link-app-${index}`}
                                            className='notranslate'
                                        >
                                            {app.displayName}
                                        </NavDropdownStyled.Item>
                                    ))}
                                </NavDropdownStyled>
                                <NavDropdownStyled title='Planos' id='collasible-nav-dropdown'>
                                    {navApps?.map((app, index) => (
                                        !!app?.plansUrl &&
                                        <NavDropdownStyled.Item
                                            onClick={() => handleRedirect(app?.plansUrl)}
                                            eventKey={`link-plans-${index}`}
                                            className='notranslate'
                                        >
                                            {app.displayName}
                                        </NavDropdownStyled.Item>
                                    ))}
                                </NavDropdownStyled>
                            </>
                        }
                    </NavStyled>
                    <NavStyled>
                        {
                            user?.name && 
                            <>
                                <NavStyled.Link eventKey='link-profile'>
                                    <Button
                                        label='Ver Perfil'
                                        onClick={() => navigate('/profile')}
                                        theme='secondary'
                                        type='button'
                                        sx={{ minWidth: '112px' }}         
                                        fontSize='12px'
                                        fontWeight='600'
                                    />
                                </NavStyled.Link>
                                <NavStyled.Link eventKey='link-sign-out'>
                                    <Button
                                        label='Sair'
                                        onClick={handleSignOut}
                                        theme='secondary'
                                        type='button'
                                        sx={{
                                            minWidth: '112px',
                                            backgroundColor: '#fd5c63',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#E32636'
                                            },
                                            '&:active, &:focus': {
                                                backgroundColor: '#fd5c63'
                                            }
                                        }}
                                        fontSize='12px'
                                        fontWeight='600'
                                    />
                                </NavStyled.Link>
                            </>
                        }
                        {
                            !user?.name &&
                            <>
                                <NavStyled.Link eventKey='link-sign-in'>
                                    <Button
                                        label='Entrar'
                                        onClick={() => navigate('/sign-in')}
                                        theme='secondary'
                                        type='button'
                                        sx={{ minWidth: '112px'}}
                                        fontSize='12px'
                                        fontWeight='600'
                                    />
                                </NavStyled.Link>
                                <NavStyled.Link eventKey='link-sign-up'>
                                    <Button
                                        label='Cadastrar'
                                        onClick={() => navigate('/sign-up')}
                                        theme='secondary'
                                        type='button'
                                        sx={{ 
                                            minWidth: '112px', 
                                            backgroundColor: '#50C878', 
                                            color: 'white', 
                                            '&:hover': { 
                                                backgroundColor: '#38a852' 
                                            }, 
                                            '&:active, &:focus': { 
                                                backgroundColor: '#50C878' 
                                            } 
                                        }}
                                        fontSize='12px'
                                        fontWeight='600'
                                    />
                                </NavStyled.Link>
                            </>
                        }
                        {/*
                          <Link to='/my-cart'>
                                <Box sx={{ display: { xs: 'flex', md: 'flex' }, cursor: 'pointer' }}>
                                    <FontAwesomeIcon icon={faShoppingCart} size='2xl'/>
                                    <CounterStyled>{plans.length}</CounterStyled>
                                </Box>
                            </Link>
                        */}
                    </NavStyled>
                </NavbarStyled.Collapse>
            </ContainerStyled>
        </NavbarStyled>
    );
}

export default NavBar;
