import React, { useEffect, useState, useContext } from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate, useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import {
    CheckoutPageContainer,
    CheckoutPageTitle,
    CheckoutPlans,
    CheckoutPlanDetails,
    PlanName,
    PlanPrice,
    PlanDuration,
    SmallText,
    BadgeStyled,
} from './CheckoutStyled';
import { AlertBox } from '../../../components/alert-box/AlertBox';
import { usePlans } from '../../../hooks/usePlans';
import { Button } from '../../../components/button/Button';
import { CustomMessage } from '../../../components/custom-message/CustomMessage';
import { FeaturesList } from '../../../components/features-list/FeaturesList';
import { useCustomCheckout } from '../../../hooks/useCustomCheckout';
import { useGetCurrentUser } from '../../../hooks/useGetCurrentUser';
import checkoutService from '../../../services/checkoutService';
import { CartContext } from '../../../contexts/CartContext';
import { AppsContext } from '../../../contexts/AppsContext';

const Checkout = () => {
    const appName = new URL(window.location).searchParams.get('app');
    const type = new URL(window.location).searchParams.get('type') || 'subscription';
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [selectedApp, setSelectedApp] = useState('');
    const [errorProcessingMsg, setErrorProcessingMsg] = useState('');
    // eslint-disable-next-line
    const { cart, setCart } = useContext(CartContext);
    const { apps } = useContext(AppsContext);
    const navigate = useNavigate();
    const location = useLocation();
    const listPlans = usePlans(appName, type);
    const getUser = useGetCurrentUser();
    const redirectToCheckout = useCustomCheckout();
    const showFeatures = false;
    const getPlanRecurringInterval = (multiplier) => ({
        day: multiplier === 1 ? 'dia' : `${multiplier} dias`,
        week: multiplier === 1 ? 'semana' : `${multiplier} semanas`,
        month: multiplier === 1 ? 'mês' : `${multiplier} meses`,
        year: multiplier === 1 ? 'ano' : `${multiplier} anos`,
    });

    useEffect(() => {
        const setPurchasePlans = async () => {
            const { status, data } = await listPlans();

            if (status === 200 && data?.length) {
                setPlans(data);
                const index = Math.floor(data.length / 2);
                setSelectedPlan(data[index].id);
                
                // View item list tag configuration: 
                // Source: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_list-gtm
                TagManager.dataLayer({
                    dataLayer: {
                        ecommerce: null
                    }
                });
                TagManager.dataLayer({
                    dataLayer: {
                        event: "view_item_list",
                        ecommerce: {
                            item_list_id: appName,
                            item_list_name: selectedApp,
                            items: data.map((plan, idx) => ({
                                item_id: plan.id,
                                item_name: plan.name,
                                index: idx,
                                item_list_id: appName,
                                item_list_name: selectedApp,
                                item_variant: plan.name,
                                price: plan.price,
                                quantity: 1,
                            }))
                        }
                    }
                });
            }
        };
        if (selectedApp) {
            setPurchasePlans();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedApp]);

    useEffect(() => {
        const setCurrentApp = () => {
            const currentApp = apps.filter(app => app.name === appName);
            if (currentApp.length) {
                setSelectedApp(currentApp[0]?.displayName);
            } 
            else {
                setSelectedApp('');
            }
        }

        setCurrentApp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apps, location])

    const handleSelectedPlan = (plan, idx) => {
        const redirectUserToCheckout = async () => {
            const { status, currentUser } = await getUser();
            checkoutService.addPlanToCart(plan, appName, selectedApp, idx);
            setCart(checkoutService.getCart());

            // Add to cart tag configuration: 
            // Source: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_to_cart-gtm
            TagManager.dataLayer({
                dataLayer: {
                    ecommerce: null
                }
            });
            TagManager.dataLayer({
                dataLayer: {
                    event: "add_to_cart",
                    ecommerce: {
                        currency: plan.currency,
                        value: plan.price,
                        items: [{
                            item_id: plan.id,
                            item_name: plan.name,
                            index: idx,
                            item_list_id: appName,
                            item_list_name: selectedApp,
                            item_variant: plan.name,
                            price: plan.price,
                            quantity: 1,
                        }]
                    }
                }
            });

            if (status !== 200 || !currentUser?.id) {
                console.log('User not logged in...', status, currentUser);
                return navigate('/sign-up');
            }

            redirectToCheckout(plan, currentUser).then(({ status, data }) => {
                if (status !== 200) {
                    setErrorProcessingMsg('Erro ao processar o carrinho, tente novamente mais tarde.');
                    return;
                }
                const { redirectUrl } = data;
                window.open(redirectUrl, '_self');
            });
        }

        // Select item tag configuration: 
        // Source: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#select_item-gtm
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: null
            }
        });
        TagManager.dataLayer({
            dataLayer: {
                event: "select_item",
                ecommerce: {
                    item_list_id: appName,
                    item_list_name: selectedApp,
                    items: [{
                        item_id: plan.id,
                        item_name: plan.name,
                        index: idx,
                        item_list_id: appName,
                        item_list_name: selectedApp,
                        item_variant: plan.name,
                        price: plan.price,
                        quantity: 1,
                    }]
                }
            }
        });
        
        if (plan.price === 0) {
            return navigate('/sign-up');
        }

        redirectUserToCheckout();
    };

    if (!appName) return <CustomMessage notFound={true}/>;

    return (
        <CheckoutPageContainer className='justify-content-center d-flex'>
            <CheckoutPageTitle>Escolha um plano {!!selectedApp ? `do ${selectedApp}` : ''}</CheckoutPageTitle>
            <CheckoutPlans>
                <Row className='g-4' style={{ margin: '1% 1% 0 1%' }}>
                    {plans.map((plan, idx) => (
                        // eslint-disable-next-line
                        <Col key={plan.id} xs={12} sm={12} md={6} lg={4} className='justify-content-center d-flex'>
                            <CheckoutPlanDetails>
                                {(plan.id === selectedPlan) && <BadgeStyled>Mais Popular</BadgeStyled>}
                                <img
                                    src={plan.image}
                                    height="80"
                                    alt='plan display'
                                />
                                <PlanName className='notranslate'>{plan.name}</PlanName>
                                <PlanPrice>{plan.price === 0 ? 'Grátis*' : `R$${plan.price.toFixed(2)}*`}</PlanPrice>
                                <PlanDuration>{plan.price === 0 ? 'para sempre' : plan.interval ? `por ${getPlanRecurringInterval(plan.intervalCount)[plan.interval]}` : ''}</PlanDuration>
                                <Button
                                    theme='primary'
                                    onClick={() => handleSelectedPlan(plan, idx)}
                                    label={plan.price === 0 ? 'Começar Grátis' : 'Assinar Agora'}
                                />
                                <SmallText className='notranslate'>*{plan.description}</SmallText>
                                {showFeatures && <FeaturesList features={plan.features} listDirection='vertical'/>}
                            </CheckoutPlanDetails>
                        </Col>
                    ))}
                </Row>
            </CheckoutPlans>
            {errorProcessingMsg &&
                <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                    <Col xs={12}>
                        <AlertBox
                            severity='error'
                            message={errorProcessingMsg}
                            onClose={() => setErrorProcessingMsg('')}
                            timeout={5000}
                        />
                    </Col>
                </Row>
            }
        </CheckoutPageContainer>
    );
};

export default Checkout;