import styled from "styled-components";
import { Link as RouterLink } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

export const ContainerStyled = styled(Container)`
  max-width: 1145px;
`;

export const NavbarStyled = styled(Navbar)`
  /* background-color: #3b4658; */
  background: rgba(59,70,88, 0.90) !important;
`;

export const NavStyled = styled(Nav)`
  margin: 5px;

  .nav-link, .nav-link:active, .nav-link.active {
    color: #f3f3f3;
    max-width: fit-content;
  }
  .nav-link:hover {
    color: #ffffff;
    font-weight: 600;
  }
`;

export const NavDropdownStyled = styled(NavDropdown)`
  .dropdown-menu {
    background-color: #f3f3f3;
  }
  .dropdown-item {
    background-color: #f3f3f3;
    color: #3b4658;
  }
  .dropdown-item:active, .dropdown-item:hover {
    background-color: #f3f3f3;
    color: black;
    font-weight: 600;
  }
`;

export const BarWrapperStyled = styled.div`
  padding: 1%;
  margin-left: 1%;
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: white;
  
  &:hover, &:focus, &:visited, &:link, &:active {
    text-decoration: none;
    color: white;
  }
`;

export const UserRegistrationContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const UserRegistrationStyled = styled(RouterLink)`
  margin-right: 2%;
  color: ${(props) => props?.theme === 'primary' ? '#ffffff' : '#1f2a37' };
  background-color: ${(props) => props?.theme === 'primary' ? '#1f2a37' : '#f6f5ff' };
  border: ${(props) => props?.theme === 'primary' ? '1px solid #1f2a37' : '1px solid #f3f4f6' };
  border-radius: 5px;
  padding: 0.25rem 1rem;
  min-width: 6rem;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    color: ${(props) => props?.theme === 'primary' ? '#ffffff' : '#1f2a37' };
  }

  &:-webkit-any-link {
    text-decoration: none;
    color: ${(props) => props?.theme === 'primary' ? '#ffffff' : '#1f2a37' };
    cursor: pointer;
  }
`;

export const CounterStyled = styled.div`
	background-color: #f3f3f3;
  	color: #1f2a37;
  	border-radius: 50%;
  	width: 1rem;
  	height: 1rem;
  	font-size: 0.75rem;
  	text-align: center;
  	line-height: 1rem;
	justify-content: center;
	align-items: center;
`;