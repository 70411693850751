import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
	Title,
	SettingsContainer,
} from './SettingsStyled';
import { UserContext } from '../../../contexts/UserContext';
import { Form } from 'react-bootstrap';
import { useUpdateSettings } from '../../../hooks/useUpdateSettings';
import { Button } from '../../../components/button/Button';
import { AlertBox } from '../../../components/alert-box/AlertBox';

const Settings = () => {
	const { user } = useContext(UserContext);
	const { settings: userSettings } = user;
	const [settings, setSettings] = useState(userSettings);
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [warningMessage, setWarningMessage] = useState('');
	const updateSettings = useUpdateSettings();
	const appsMapping = {
		COPYCAT_IMPORTER: 'APP_COPYCAT-IMPORTER',
		COPYCAT_ADS: 'APP_COPYCAT-ADS',
	}
	const settingsMapping = {
		'APP_COPYCAT-IMPORTER': {
			title: 'Copycat Importer',
			settings: [
				{
					label: 'Importar variantes',
					hint: 'Importar variantes de produtos',
					fieldName: 'importVariants',
				},
				{
					label: 'Publicar produtos após importação',
					hint: 'Deixa o produto visível publicamente após a importação',
					fieldName: 'publishAfterImport',
				}
			]
		}
	}

	const onToggle = (appName, fieldName) => {
		setSettings({
			...settings,
			[appName]: {
				...settings[appName],
				[fieldName]: !settings[appName][fieldName], // toggle boolean value
			}
		})
	}

	const onSave = async () => {
		const updatedSettings = {
			'copycat-importer': settings[appsMapping.COPYCAT_IMPORTER],
			'copycat-ads': settings[appsMapping.COPYCAT_ADS],
		};

		const { status, data } = await updateSettings(updatedSettings);

		if (status === 200) {
			if (data.updated) setSuccessMessage('Preferências salvas com sucesso!');
			else setWarningMessage('Nenhuma preferência foi alterada');
		}
		else setErrorMessage('Erro ao salvar preferências');
	}

	const handleCloseAlertError = () => {
		setErrorMessage('');
	};

	const handleCloseAlertSuccess = () => {
		setSuccessMessage('');
	};

	const handleCloseAlertWarning = () => {
		setWarningMessage('');
	};

	return (
		<div>
			<SettingsContainer style={{ marginTop: '3%' }}>
				{(settings?.[appsMapping.COPYCAT_IMPORTER] &&
					Object.keys(settings[appsMapping.COPYCAT_IMPORTER]).length ?
						(
							<>
								<Row className="justify-content-center" style={{ marginTop: '3%' }}>
									<Col xs={9} style={{ textAlign: 'center', alignItems: 'center' }}>
										<Title>{ settingsMapping[appsMapping.COPYCAT_IMPORTER].title }</Title>
									</Col>
								</Row>
								<Form>
									{settingsMapping[appsMapping.COPYCAT_IMPORTER].settings.map((setting, index) => (
										<Row className="justify-content-center" style={{ marginTop: '3%' }} key={index}>
											<Col xs={4} style={{ textAlign: 'left', alignItems: 'center', justifyContent: 'center' }}>
												<Form.Check
													type='checkbox'
													id={index}
													label={setting.label}
													checked={!!settings?.[appsMapping.COPYCAT_IMPORTER][setting.fieldName]}
													onChange={() => { onToggle(appsMapping.COPYCAT_IMPORTER, setting.fieldName) }}
												/>
											</Col>
										</Row>
									))}
									{settingsMapping[appsMapping.COPYCAT_IMPORTER].settings.length && (
										<Row className="justify-content-center" style={{ marginTop: '3%' }}>
											<Col xs={4} style={{ textAlign: 'left', alignItems: 'center', justifyContent: 'center' }}>
												<Button label='Salvar' onClick={onSave} />
											</Col>
										</Row>
									)}
								</Form>
							</>
						) : null)
				}
				{!!errorMessage && (
					<Row className="justify-content-center">
						<Col xs={9}>
							<AlertBox
								severity='error'
								message={errorMessage}
								onClose={handleCloseAlertError}
							/>
						</Col>
					</Row>
				)}
				{!!successMessage && (
					<Row className="justify-content-center">
						<Col xs={9}>
							<AlertBox
								severity='success'
								message={successMessage}
								onClose={handleCloseAlertSuccess}
							/>
						</Col>
					</Row>
				)}
				{!!warningMessage && (
					<Row className="justify-content-center">
						<Col xs={9}>
							<AlertBox
								severity='warning'
								message={warningMessage}
								onClose={handleCloseAlertWarning}
							/>
						</Col>
					</Row>
				)}
			</SettingsContainer>
		</div>
	)
}

export default Settings;