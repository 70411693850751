import * as React from 'react';
import { ButtonLabelStyled, ButtonStyled } from './ButtonStyled';
import {
    Button as MUIButton,
} from '@mui/material';
import { theme as customTheme } from '../../styles/theme';
import { ThemeProvider } from "@mui/material";

// type could also be "submit" for forms
export const Button = ({
   label,
   onClick,
   sx,
   margin,
   fontSize,
   fontWeight,
   theme = 'primary',
   type = 'button',
   disabled = false,
}) => (
    <ThemeProvider theme={customTheme}>
        <ButtonStyled disabled={disabled} margin={margin}>
            <MUIButton variant="contained" color={theme} type={type} onClick={onClick} disabled={disabled} sx={sx}>
                <ButtonLabelStyled fontSize={fontSize} fontWeight={fontWeight}>{label}</ButtonLabelStyled>
            </MUIButton>
        </ButtonStyled>
    </ThemeProvider>
);