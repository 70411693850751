import usersApiInstance from '../services/usersApi';
import { useApiInterceptor } from './useApiInterceptor';

export function useApps() {
    const usersApi = useApiInterceptor(usersApiInstance);

    return async () => {
        try {
            const validateStatus = () => true;
            const { status, data } =
                await usersApi.get('/users/apps', { validateStatus });

            return { status, data };
        } catch (error) {
            // any network error will fall under this block
            return { status: 500 , data: null };
        }
    };
}