import { useLocation } from "react-router-dom";

export function useAutomaticScroll() {
    const location = useLocation();

    return (forceToTop = false) => {
        if (location?.hash && !forceToTop) {
            const id = location.hash.replace('#', '');
            const anchorElement = document.getElementById(id);
            if (anchorElement) {
                anchorElement.scrollIntoView({
                    behavior: 'smooth',
                });
                return;
            }
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
}