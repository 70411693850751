import axios from 'axios';

const importerClient = axios.create({
    baseURL: process.env.REACT_APP_IMPORTER_SERVICE_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default importerClient;
