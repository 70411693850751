// Brazilian phone validation
export const formatPhoneE164BR = (phone) => {
    // Format phone number according to the E.164 standard. 
    // Source: https://en.wikipedia.org/wiki/E.164
    // This format is required by Google Ads enhanced conversion to work
    // If we expand worldwide, we will need to improve this.
    // Here we're assuming that the phone number is already a valid one.
    if (!phone) return phone;
    let phoneNumberE164BR = phone.replace(/[^\d+]/g, '');
    if (!phoneNumberE164BR.startsWith('+55')) {
        phoneNumberE164BR = '+55' + phoneNumberE164BR;
    }
    return phoneNumberE164BR;
}