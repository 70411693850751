import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const ProfileContainer = styled(Container)`
    background-color: #f9fafb;
    border: 1px solid #f3f4f6;
    padding: 2% 0 2% 0;
    border-radius: 28px;

    @media (min-width: 0px) {
      max-width: 90%; /* For xs devices */
    }

    @media (min-width: 576px) {
        max-width: 75%; /* For sm devices */
    }

    @media (min-width: 768px) {
        max-width: 65%; /* For md devices */
    }

    @media (min-width: 992px) {
        max-width: 55%; /* For lg devices */
    }

    @media (min-width: 1200px) {
        max-width: 50%; /* For xl devices */
    }

    @media (min-width: 1400px) {
        max-width: 45%; /* For xxl devices */
    }

`;

export const Label = styled.span`
  font-family: DM Sans, Inter, Arial, sans-serif;
  font-weight: 400;
  line-height: 128%;
  color: #374151;
  margin-bottom: 2%;
`;

export const ProfileImg = styled.img`
    width: 126px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
`;