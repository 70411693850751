import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';

const AuthorizedPage = ({ children }) => {
    const { user, isLoading } = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    if (loading) {
        // Display a loading message
        return <div>Carregando...</div>;
    }

    if (user?.email) {
        return children;
    } else {
        return <Navigate to='/sign-in' />;
    }
};

export default AuthorizedPage;