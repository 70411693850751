import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Link = styled(RouterLink)`
  text-align: justify;
  color: #6b7280;
  text-decoration: "none";
  font-weight: 400;
  line-height: 144%;

  &:hover {
    text-decoration: none;
    color: #1f2a37;
    font-weight: 600;
  }

  &:-webkit-any-link {
    text-decoration: none;
    cursor: pointer;
  }
`;