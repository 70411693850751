import styled, { keyframes } from 'styled-components';

export const LoaderStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const spinAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const SpinnerStyled = styled.div`
    border-top-color: #3b4658;
    border-right-color: #3b4658;
    border-left-color: #3b4658;
    border-bottom-color: #f3f3f3;
    border-style: solid;
    border-width: 4px;
    border-radius: 12px;
    width: 25px;
    height: 25px;
    animation: ${spinAnimation} 0.5s linear infinite;
`;