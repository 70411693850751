import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderStyled = styled.div`
  margin: 2.5%;
`;

const TitleStyled = styled.h1`
  text-align:center;
  position: relative;
  padding: 0;
  font-family: "Roboto", "Raleway", sans-serif;
  letter-spacing: 2px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2em;
  color: #373b3c;
`;

const ButtonStyled = styled.div`
  margin: 3%;
`;

const ButtonLabelStyled = styled.span`
  font-family: "Roboto", "Raleway", sans-serif;
  font-size: 1rem;
  font-weight: 400;
`;

const GridItemStyled = styled.div`
  margin-bottom: 1%;
  padding: 1%;
`;

const GridItemAlertStyled = styled.div`
  margin-bottom: 0.5%;
  padding: 0.5%;
  min-height: 70px;
`;

const GridContainerStyled = styled.div`
  //margin-top: 1%;
`;

const AlertStyled = styled.div`
  margin: 0.5%;
  padding: 0.5%;
`;

const EditLinkStyled = styled(Link)`
    text-decoration: underline;
    color: inherit;

    &:hover {
        text-decoration: underline;
        color: inherit;
    }
    
    &:focus {
        text-decoration: underline;
        color: inherit;
    }
`;

export {
    HeaderStyled,
    TitleStyled,
    ButtonStyled,
    GridItemStyled,
    GridItemAlertStyled,
    GridContainerStyled,
    AlertStyled,
    ButtonLabelStyled,
    EditLinkStyled,
};