import usersApiInstance from '../services/usersApi'
import TokenService from '../services/tokenService';
import { useApiInterceptor } from './useApiInterceptor';
import importerService from '../services/importerService';
import TagManager from 'react-gtm-module';
import { formatPhoneE164BR } from '../utils/formatPhoneE164';


export function useGetCurrentUser() {
    const usersApi = useApiInterceptor(usersApiInstance);

    return async () => {
        try {
            const tokens = TokenService.getTokens();
            const validateStatus = () => true;
            // console.log('fetching user data?', tokens?.accessToken ? 'yes' : 'no');
            if (tokens?.accessToken) {
                const { status, data } = await usersApi.get('/users/me', { validateStatus });
                if (status === 200) {
                    // console.log('user.................', data.user);
                    const { user: currentUser } = data;

                    if (currentUser?.settings?.['APP_COPYCAT-IMPORTER']) {
                        importerService.setImporterSettings(currentUser.settings['APP_COPYCAT-IMPORTER']);
                    }

                    const userData = {
                        id: currentUser.id,
                        email: currentUser.email,
                        phone: formatPhoneE164BR(currentUser.whatsapp),
                    }

                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'user_update',
                            user: userData,
                        }
                    });

                    return { status, currentUser };
                }
            }

            // remove invalid token from the local storage
            TokenService.removeTokens();
            return { status: 401, currentUser: {} };
        } catch (error) {
            // any network error will fall under this block
            // remove invalid token from the local storage
            TokenService.removeTokens();
            return { status: 500, currentUser: {} };
        }
    };
}