import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
    ProfileContainer,
    Label,
} from './ProfileStyled';
import { Button } from '../../../components/button/Button';
import PlgAlert from '../../../components/plg-alert/PlgAlert';
import { UserContext } from '../../../contexts/UserContext';
import { firstNameFormatter } from '../../../utils/stringFormatter';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const handleAccessProducts = () => {
        navigate('/apps');
    };
    
    const handleSettings = () => {
        navigate('/settings');
    }

    return (
        <div>
            <PlgAlert/>
            <ProfileContainer style={{ marginTop: '3%' }}>
                <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                    <Col xs={12} style={{ textAlign: 'center', alignItems: 'center' }}>
                        <h1>Bem-vindo, {firstNameFormatter(user?.name) || 'Convidado'}</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                    <Col xs={9} style={{ textAlign: 'center', alignItems: 'center' }}>
                        <Label>Clique no botão abaixo para acessar os apps Copycat</Label>
                    </Col>
                </Row>
                <Row className="justify-content-center" style={{ marginTop: '3%', marginBottom: '3%' }}>
                    <Col xs={3} style={{ textAlign: 'center', alignItems: 'center' }}>
                        <Button
                            label='Acessar Apps'
                            onClick={handleAccessProducts}
                            theme='primary'
                            type='button'
                        />
                    </Col>
                    {user?.settings && Object.keys(user?.settings).length ? (
                        <Col xs={3} style={{ textAlign: 'center', alignItems: 'center' }}>
                            <Button
                                label='Preferências'
                                onClick={handleSettings}
                                theme='primary'
                                type='button'
                            />
                        </Col>
                    ) : null}
                </Row>
            </ProfileContainer>
        </div>
    );
};

export default Profile;
