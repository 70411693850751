class SignalService {
    getSignal() {
        return JSON.parse(localStorage.getItem('signal') || '{}');
    }
    
    getSignalValue() {
        const signal = this.getSignal();
        return signal?.value;
    }
    
    toggleSignal() {
        if (!this.getSignalValue()) {
            this.setSignal();
        } 
        else {
            this.removeSignal();
        }
    }

    setSignal() {
        const signal = { value: true };
        localStorage.setItem('signal', JSON.stringify(signal));
    }

    removeSignal() {
        localStorage.removeItem('signal');
    }
}

const signalService = new SignalService();

export default signalService;
