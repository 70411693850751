class TokenService {
    getTokens() {
        return JSON.parse(localStorage.getItem('tokens') || '{}');
    }
    
    getLocalRefreshToken() {
        const tokens = this.getTokens();
        return tokens?.refreshToken;
    }
    
    getLocalAccessToken() {
        const tokens = this.getTokens();
        return tokens?.accessToken;
    }
    
    updateLocalAccessToken(token) {
        const tokens = this.getTokens();
        tokens.accessToken = token;
        localStorage.setItem('tokens', JSON.stringify(tokens));
    }
    
    setTokens(tokens) {
        localStorage.setItem('tokens', JSON.stringify(tokens));
    }

    removeTokens() {
        localStorage.removeItem('tokens');
    }
}

const tokenService = new TokenService();

export default tokenService;
