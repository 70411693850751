import paymentsApiInstance from '../services/paymentsApi';
import { useApiInterceptor } from './useApiInterceptor';

export function usePlans(appName, type) {
    const paymentsApi = useApiInterceptor(paymentsApiInstance);

    return async () => {
        try {
            const validateStatus = () => true;
            const { status, data } =
                await paymentsApi.get(`/plans?app=${appName}&type=${type}`, { validateStatus });

            return { status, data };
        } catch (error) {
            // any network error will fall under this block
            return { status: 500 , data: null };
        }
    };
}