class CheckoutService {
    getCart() {
        return JSON.parse(localStorage.getItem('cart') || '{}');
    }

    getLatestPlanChosen() {
        const cart = this.getCart();
        
        if (!cart.plans?.length) return null;
        
        return cart.plans[cart.plans.length - 1];
    }
    
    addPlanToCart(plan, appName, appDisplayName, idx) {
        const cart = this.getCart();
        
        if (!cart.plans?.length) {
            cart.plans = [];
        }
        
        const planNotInCart = cart.plans.findIndex((p) => p.id === plan.id) < 0;
        
        if (planNotInCart) {
            // save only the necessary data to the cart
            const {
                id,
                image,
                name,
                price,
                currency,
                interval,
                description,
                features,
                intervalCount,
            } = plan;
            cart.plans.push({
                appName,
                appDisplayName,
                id,
                idx,
                image,
                name,
                price,
                currency,
                interval,
                description,
                features,
                intervalCount,
            });
        }
        
        localStorage.setItem('cart', JSON.stringify(cart));
    }
    
    addMultiplePlansToCart(plans) {
        if (Array.isArray(plans)) {
            plans.forEach((plan) => this.addPlanToCart(plan));
        }
    }
    
    removePlanFromCart(planId) {
        const cart = this.getCart();
        
        if (!cart.plans?.length) return;
        
        cart.plans = cart.plans.filter((p) => p.id !== planId);
        localStorage.setItem('cart', JSON.stringify(cart));
    }

    resetCart() {
        localStorage.removeItem('cart');
    }

    getCheckoutData() {
        return JSON.parse(localStorage.getItem('checkout') || '{}');
    }

    resetCheckoutData() {
        localStorage.removeItem('checkout');
    }

    popCheckoutData() {
        const checkoutData = this.getCheckoutData();
        this.resetCheckoutData();
        return checkoutData;
    }

    setCheckoutData(planId, user) {
        const cart = this.getCart();
        if (!cart.plans?.length) return;
        const checkoutPlan = cart.plans.filter((plan) => plan.id === planId)[0];
        if (!checkoutPlan) return;
        const checkoutData = {
            plan: checkoutPlan,
            user: user,
        }
        localStorage.setItem('checkout', JSON.stringify(checkoutData));
        this.removePlanFromCart(planId);
    }

}

const checkoutService = new CheckoutService();

export default checkoutService;
