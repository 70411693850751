import styled from 'styled-components';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const PasswordContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
  	//postion: absolute;
`;

export const ShowPasswordIcon = styled(Visibility)`
  //position: absolute;
  color: #374151;
  min-width: 50px;
`;

export const HidePasswordIcon = styled(VisibilityOff)`
  //position: absolute;
  color: #374151;
  min-width: 50px;
`;