import React, { useContext }  from 'react';
import { AppsContext } from '../../contexts/AppsContext';
import GridExample from '../../components/cards-grid/CardsGrid';
import { Loader } from '../../components/loader/Loader';
import PlgAlert from '../../components/plg-alert/PlgAlert';

const AppsShowcasePage = () => {
    const { apps, isLoading } = useContext(AppsContext);
    
    return (
        <div>
            <PlgAlert/>
            {(apps.length > 0 && <GridExample cards={apps}/>) || (isLoading && <Loader/>)}
        </div>
    )
}

export default AppsShowcasePage;