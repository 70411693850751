import { useContext } from 'react';
import TokenService from '../services/tokenService';
import { SigningOutContext } from '../contexts/SigningOutContext';
import importerService from '../services/importerService';


export function useSignOut() {
    const { setIsSigningOut } = useContext(SigningOutContext);

    return () => {
        TokenService.removeTokens();
        setIsSigningOut(true);
        importerService.removeImporterSettings();
    };
}