import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    DocFrame,
    DocContainer,
} from './DocStyled';

const Doc = ({ file }) => {
    return (
        <DocContainer>
            <Row>
                <Col xs={12}>
                    <DocFrame
                        title='Terms'
                        src={file}
                    />
                </Col>
            </Row>
        </DocContainer>
    );
};

export default Doc;