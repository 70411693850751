import React, { useEffect, useState } from 'react';
import { CustomMessage } from '../../../components/custom-message/CustomMessage';
import { useValidateEmail } from '../../../hooks/useValidateEmail';
import signalService from '../../../services/signalService';

const EmailValidationPage = () => {
    // for email validation we receive a token in the url
    const token = new URL(window.location).searchParams.get('token');
    const [emailValidationStatus, setEmailValidationStatus] = useState(0);
    const [loading, setLoading] = useState(true);
    const validateEmail = useValidateEmail();
    const successComponent = (
        <CustomMessage notFound={false} message={'Conta validada com sucesso!'} />
    );
    const errorComponent = (
        <CustomMessage notFound={false} message={'Não foi possível validar a conta!'} />
    );
    
    useEffect(() => {
        if (token && emailValidationStatus === 0) {
            validateEmail(token).then(({ status }) => {
                setEmailValidationStatus(status);
                setLoading(false);
                if (status === 200) {
                    // Signal to other copycat-web tabs that were changes on user's isActive status
                    signalService.toggleSignal();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);
    
    if (!token) return errorComponent;
    
    if (loading) return <div>Carregando...</div>;
    
    return emailValidationStatus === 200 ? successComponent : errorComponent;
};

export default EmailValidationPage;
