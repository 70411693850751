import { useNavigate } from "react-router-dom";

export function useHandleRedirect() {
    const navigate = useNavigate();

    return (url) => {
        if (!url) return;
        if (url.startsWith('http')) return window.open(url, '_blank');
        else if (url.startsWith('/')) return navigate(url);
        navigate(`/${url}`);
    };
}