import React, { createContext, useState } from 'react';

export const SigningOutContext = createContext({});

export const SigningOutProvider = ({ children }) => {
    // add isSigningOut state which will be used in the Sign Out flow
    const [isSigningOut, setIsSigningOut] = useState(false);
    
    return (
        <SigningOutContext.Provider value={{ isSigningOut, setIsSigningOut }}>
            {children}
        </SigningOutContext.Provider>
    );
};
