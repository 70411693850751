import importerApiInstance from '../services/importerApi';
import { useApiInterceptor } from './useApiInterceptor';

export function useImporterCopy() {
    const importerApi = useApiInterceptor(importerApiInstance);
    
    return async (requestBody) => {
        try {
            const validateStatus = () => true;
            const { status, data } =
                await importerApi.post('/copy', requestBody, { validateStatus });

            return { status, data };
        } catch (error) {
            // any network error will fall under this block
            return { status: 500 , data: null };
        }
    };
}