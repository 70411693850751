import usersApi from '../services/usersApi';
import importerService from '../services/importerService';

export function useUpdateSettings() {
    return async (settings) => {
        try {
            const requestBody = { settings };
            const validateStatus = () => true;
            const { status, data } =
                await usersApi.patch('/users/settings', requestBody, { validateStatus });

            if (status === 200 && data?.updated && settings['copycat-importer']) {
                importerService.setImporterSettings(settings['copycat-importer'])
            }

            return { status, data };
        } catch (error) {
            // any network error will fall under this block
            return { status: 500 , data: null };
        }
    };
}