import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import {Link as RouterLink} from "react-router-dom";

export const LandingPageContainer = styled(Container)``;

export const HeaderRow = styled(Row)`
  margin-top: 2%;
  justify-content: center;
`;

export const HeaderCol = styled(Col)`
  text-align:center;
`;

export const Title = styled.span`
  text-align:center;
  font-size: 2.5rem;
  font-family: "Roboto", "Raleway", sans-serif;
  font-weight: 600;
  color: #373b3c;
`;

export const Description = styled.span`
  display: block;
  font-family: "Roboto", "Raleway", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 3em;
  color: #373b3c;
  text-align:center;
`;

export const RegisterLink = styled(RouterLink)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }

    &:-webkit-any-link {
        text-decoration: none;
        cursor: pointer;
    }
`;

export const LinkLabel = styled.span`
    font-family: DM Sans, Inter, Arial, sans-serif;
    font-weight: 400;
    line-height: 128%;
    color: #6b7280;
    margin-bottom: 2%;

    &:hover {
        color: #1f2a37;
    }
`;

export const ContentRow = styled(Row)`
  margin-top: 5%;
  justify-content: center;
`;

export const DemoVideoCol = styled(Col)`
`;

export const DemoVideo = styled.iframe`
  /* margin-top: 6%; */
  border-radius: 20px;
  min-height: 20.75rem;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

export const FormCol = styled(Col)``;

export const TutorialRow = styled(Row)`
  margin: 5% 0 5% 0;
  justify-content: center;
`;

export const TutorialCol = styled(Col)``;