
const fieldName = 'importerSettings';

class ImporterService {
    getImporterSettings() {
        return JSON.parse(localStorage.getItem(fieldName) || '{}');
    }

    setImporterSettings(settings) {
        const currentSettings = this.getImporterSettings();
        localStorage.setItem(fieldName, JSON.stringify({
            ...currentSettings,
            ...settings,
        }));
    }

    removeImporterSettings() {
        localStorage.removeItem(fieldName);
    }
}

const importerService = new ImporterService();

export default importerService;
