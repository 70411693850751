import React from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { Button } from '../button/Button';
import Logo from '../../assets/logo.svg';
import { CustomMessageContainer, TitleStyled, LogoStyled, Label } from './CustomMessageStyled';
import checkoutService from '../../services/checkoutService';
import signalService from '../../services/signalService';

export const CustomMessage = ({ message = 'Algo inesperado aconteceu, tente novamente mais tarde ou entre em contato', notFound = true }) => {
    const navigate = useNavigate();
    const isPayFlow = window.location.pathname.includes('/checkout/');
    const isSuccessPayFlow = window.location.pathname.includes('/checkout/success');
    
    if (isPayFlow) {
        const planId = new URL(window.location).searchParams.get('planId');
        const userId = new URL(window.location).searchParams.get('userId');
        if (planId && userId) {
            const { plan, user } = checkoutService.popCheckoutData();   
            if (plan?.id) {
                if (isSuccessPayFlow) {
                    // Purchase tag configuration: 
                    // Source: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#purchase-gtm
                    TagManager.dataLayer({
                        dataLayer: {
                            ecommerce: null
                        }
                    });
                    TagManager.dataLayer({
                        dataLayer: {
                            event: "purchase",
                            user: user,
                            ecommerce: {
                                transaction_id: `${userId}.${planId}`,
                                currency: plan.currency,
                                value: plan.price,
                                items: [{
                                    item_id: plan.id,
                                    item_name: plan.name,
                                    index: plan.idx,
                                    item_list_id: plan.appName,
                                    item_list_name: plan.appDisplayName,
                                    item_variant: plan.name,
                                    price: plan.price,
                                    quantity: 1,
                                }]
                            }
                        }
                    });

                    // Signal to other copycat-web tabs that were changes on user's plans status
                    signalService.toggleSignal();
                }
                else {
                    // Remove from cart tag configuration: 
                    // Source: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#remove_from_cart-gtm
                    TagManager.dataLayer({
                        dataLayer: {
                            ecommerce: null
                        }
                    });
                    TagManager.dataLayer({
                        dataLayer: {
                            event: "remove_from_cart",
                            ecommerce: {
                                transaction_id: `${userId}.${planId}`,
                                currency: plan.currency,
                                value: plan.price,
                                items: [{
                                    item_id: plan.id,
                                    item_name: plan.name,
                                    index: plan.idx,
                                    item_list_id: plan.appName,
                                    item_list_name: plan.appDisplayName,
                                    item_variant: plan.name,
                                    price: plan.price,
                                    quantity: 1,
                                }]
                            }
                        }
                    });
                }
                checkoutService.resetCheckoutData();
                checkoutService.resetCart();
            }
        }
    }
    
    const handleAccessProducts = () => {
        navigate('/apps');
    };

    return (
        <CustomMessageContainer style={{ marginTop: '3%' }}>
            <Row>
                <Col xs={12} className='text-center'>
                    <LogoStyled src={Logo} alt='logo' />
                    <TitleStyled>{notFound ? '404 - Não Encontrado' : message}</TitleStyled>
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '3%' }}>
                <Col xs={9} style={{ textAlign: 'center', alignItems: 'center' }}>
                    <Label>Clique no botão abaixo para acessar os apps Copycat</Label>
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '3%', marginBottom: '3%' }}>
                <Col xs={9} style={{ textAlign: 'center', alignItems: 'center' }}>
                    <Button
                        label='Acessar Apps'
                        onClick={handleAccessProducts}
                        theme='primary'
                        type='button'
                    />
                </Col>
            </Row>
        </CustomMessageContainer>
    );
};