import React from 'react';
import { useHandleRedirect } from '../../hooks/useHandleRedirect';
import { Card, Col, Container, Row } from 'react-bootstrap';
import {
    CardStyled,
    CardTextStyled,
    CardDemoVideo,
    ImgStyled,
    CTAContainerStyled, BtnStyled,
} from './CardsGridStyled';
import { truncateString } from '../../utils/stringFormatter';
import logo from '../../assets/logo.svg';
import { Button } from '../button/Button';

function GridExample({ cards }) {
    const handleRedirect = useHandleRedirect();
    
    const renderButton = (link, id) => (
        <BtnStyled>
            <Button
                label={link.name}
                key={id}
                onClick={() => handleRedirect(link.disabled ? undefined : link.url)}
                disabled={!!link.disabled}
            />
        </BtnStyled>
    );
    
    return (
        <Container className='justify-content-center d-flex'>
            <Row className='g-4' style={{ margin: '1% 1% 0 1%' }}>
                {cards?.map((card, index) => (
                    <Col key={index} className='justify-content-center d-flex'>
                        <CardStyled>
                            <ImgStyled variant='top' src={card?.icon || logo} />
                            <Card.Body>
                                <Card.Title className='notranslate'>{card.displayName}</Card.Title>
                                <CardTextStyled>
                                    {truncateString(card.description, 100)}
                                </CardTextStyled>
                                <br />
                                {card.demoVideo &&
                                    <CardDemoVideo
                                        src={card.demoVideo}
                                        title={card.displayName}
                                        frameBorder='0'
                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                        allowFullScreen
                                    />
                                }
                                <br/>
                                <CTAContainerStyled>
                                    {
                                        card.userConnected ?
                                            card.links?.map((link, index) => renderButton(link, index)) :
                                            card.firstAccessLinks?.map((firstAccessLink, index) => renderButton(firstAccessLink, index))
                                    }
                                </CTAContainerStyled>
                            </Card.Body>
                        </CardStyled>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default GridExample;