import React, { useContext, useEffect, useState } from 'react';
import { useAutomaticScroll } from '../../hooks/useAutomaticScroll';
import { Link } from './PlgAlertStyled';
import { Row, Col } from 'react-bootstrap';
import { AlertBox } from '../alert-box/AlertBox';
import { Button } from '../button/Button';
import { UserContext } from '../../contexts/UserContext';
import { theme } from '../../styles/theme';
import { ThemeProvider } from '@mui/material';
import { useResendEmail } from '../../hooks/useResendEmail';

const PlgAlert = ({ app, appName, error }) => {
    const resendEmail = useResendEmail();
    const automaticScroll = useAutomaticScroll();
    const { user } = useContext(UserContext);
    const [message, setMessage] = useState(null);
    const [action, setAction] = useState(null);
    const [alertTimeout, setAlertTimeout] = useState(null);
    const appId = app ? `APP_${app.toUpperCase()}` : null;
    const appSubscriptionUrl = app ? `/plans?app=${app}` : null;
    const signUpUrl = "/sign-up";
    const signInUrl = "/sign-in";

    const resendEmailHandler = async () => {
        const { status } = await resendEmail();
        if (status === 200) {
            setMessage(
                <p style={{ lineHeight: "200%" }}>
                    O email de boas-vindas foi reenviado para: {user.email}.<br />
                    Para continuar usando o <span className='notranslate'>Copycat</span>, <b>clique no link do email para validar a sua conta</b>.<br />
                    O email pode demorar até 10 minutos para aparecer em sua caixa de entrada.
                </p>
            );
        }
        else {
            setMessage(
                <p style={{ lineHeight: "200%" }}>
                    Não foi possível reenviar o email no momento.<br />
                    Tente novamente mais tarde!
                </p>
            );
        }
        setAction(null);
        setAlertTimeout(30000);
    }

    useEffect(() => {
        if (!user?.email) {
            setMessage(
                <p>
                    <i><Link to={signInUrl}>Entre</Link></i> ou <i><Link to={signUpUrl}>Cadastre-se</Link></i> para aumentar seu limite gratuito!
                </p>
            )
        }
        else if (!user?.isActive) {
            setMessage(
                <p style={{ lineHeight: "200%"}}>
                    Enviamos um email de boas-vindas para o seu endereço: {user.email}.<br />
                    Para continuar usando o <span className='notranslate'>Copycat</span>, <b>clique no link do email para validar a sua conta</b>.<br />
                    Caso não tenha recebido o email de boas-vindas, clique em "Reenviar".
                </p>
            )
            setAction(
                <Button
                    onClick={resendEmailHandler}
                    label={"Reenviar"}
                />
            )
        }
        else if (appId && !user.apps.includes(appId)) {
            setMessage(
                <p>
                    <i><Link to={appSubscriptionUrl}>Escolha um plano</Link></i> para ter acesso ilimitado ao <span className='notranslate'>{appName}</span>!
                </p>
            )
        } else {
            setMessage(null);
        }
        if (error) {
            automaticScroll(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, appId, appName, appSubscriptionUrl, error]);

    return (
        <ThemeProvider theme={theme}>
            { message &&
                <Row style={{ marginTop: '1%' }}>
                    <Col xs={12} className="justify-content-center d-flex">
                        <AlertBox
                            sx={{ 
                                display: "flex", 
                                justifyContent: "center", 
                                "& .MuiAlert-action": {
                                    display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "5px"
                                }
                            }}
                            severity={'warning'}
                            color={'primary'}
                            message={message}
                            action={action}
                            timeout={alertTimeout}
                            onClose={() => setMessage(null)}
                        />
                    </Col>
                </Row>
            }
        </ThemeProvider>
    )
};

export default PlgAlert;